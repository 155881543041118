import React, { useState, useEffect } from "react";
import { Table, Button, Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PayInPdf from "../pages/PayInPdf";
import PayoutPdf from "../pages/PayoutPdf";
import PurchaseReceipt from "../pages/PurchaseReceipt";
import "./Table.css";
import { Buttons } from "./Buttons";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import EstimationPdf from "../pages/EstimationPdf";
import API_DOMAIN from "../config/config";
const Tables = (props) => {
  return (
    <Table responsive="md">
      <thead>
        <tr>
          {props.headers.map((data, index) => (
            <th key={index}>{data}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.body.length > 0 ? (
          props.body.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {rowData.values.map((rowValue, colIndex) => (
                <td key={colIndex}>{rowValue}</td>
              ))}
            </tr>
          ))
        ) : (
          <tr className="text-center mx-auto">
            <td colSpan={props.headers.length}>No Record Found</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
const ProductTable = (props) => {
  const navigate = useNavigate();
  const handlesalesreturnprintClick = (rowData) => {
    navigate("/console/sales/return", {
      state: { type: "pdfview", rowData: rowData },
    });
  };

  return (
    <Table responsive="md">
      <tr className="">
        {props.headers.map((data) => (
          <th>{data}</th>
        ))}
      </tr>
      {props.body != null ? (
        <tbody>
          {props.body.map((rowData) => (
            <tr className="">
              {rowData.values.map((rowValue) => (
                <td>{rowValue}</td>
              ))}
              <td className="">
                <Dropdown>
                  <Dropdown.Toggle>
                    <Button className="action">
                      <BiDotsVerticalRounded />
                    </Button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handlesalesreturnprintClick(rowData)}
                    >
                      PDF
                    </Dropdown.Item>
                    <Dropdown.Item>View</Dropdown.Item>
                    <Dropdown.Item>Edit</Dropdown.Item>
                    <Dropdown.Item>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tr className="text-center mx-auto">
          <td>No Record Found</td>
        </tr>
      )}
    </Table>
  );
};

const TableUI = ({
  headers,
  body,
  style,
  type,
  rowData,
  planViewAction,
  onClick,
  onDelete,
  handleEditClick,
  pageview,
}) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(body.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, body.length);

  const currentItems = body.slice(startIndex, endIndex);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage >= -1);
  };
  useEffect(() => {
    // Reset to page 1 whenever the body changes
    setCurrentPage(1);
  }, [body]);
  const navigate = useNavigate();

  const handleUserEdit = (rowData) => {
    handleEditClick(rowData);
  };
  const handleUserDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/user.php?id=${companyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          delete_user_id: id,
        }),
      });

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleCompanyEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handlePartyPurchaseEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handlePartyPurchaseDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/purchase_party.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          delete_party_id: id,
          company_id: companyId,
        }),
      });

      const responseData = await response.json();

      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlePartyPurchaseTransactionEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handlePartySalesEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handlePartySalesDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/sales_party.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          delete_party_id: id,
          company_id: companyId,
        }),
      });

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlePartySalesTransactionEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleItemProdutEditClick = (rowData) => {
    navigate("/console/item/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleItemProductDeleteClick = async (id, onDelete) => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/products.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product_id: id,
          }),
        }
      );
      console.log(id);
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleItemProdutTransactionEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleItemCategoryEditClick = (rowData) => {
    handleEditClick(rowData);
  };

  const handleItemCategoryDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/category.php?id=${companyId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            category_id: id,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleItemCategoryTransactionEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleItemUnitEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleItemUnitDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/unit.php?id=${companyId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          unit_id: id,
        }),
      });

      const responseData = await response.json();

      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleItemUnitTransactionEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handlePurchaseBillEditClick = (rowData) => {
    navigate("/console/purchase/bill/create", {
      state: { type: "edit", rowData: rowData },
    });
  };

  const handlePurchaseBillDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/purchase_bill.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            purchase_id: id,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // const handlePurchaseBillPrintClick = (rowData) => {
  //     handleEditClick(rowData);
  //     navigate("/console/purchase/bill", { state: { type: "print", rowData: rowData } });
  // }
  const handlePurchasePayoutEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handlePurchasePayoutDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/payout.php?id=${companyId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payout_id: id,
        }),
      });

      const responseData = await response.json();

      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlePurchaseReturnEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleSalesInvoiceEditClick = (rowData) => {
    navigate("/console/sales/invoice/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleSalesInvoiceDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/salesinvoice.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            invoice_id: id,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSalesInvoicePrintClick = (rowData) => {
    handleEditClick(rowData);
    navigate("/console/sales/invoice", {
      state: { type: "print", rowData: rowData },
    });
  };

  const handleSalesEstimatePrintClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleSalesEstimateEditClick = (rowData) => {
    navigate("/console/sales/estimation/create", {
      state: { type: "edit", rowData: rowData },
    });
  };

  const handleSalesEstimateDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/sales_estimation.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            estimate_id: id,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSalesPayinEditClick = (rowData) => {
    handleEditClick(rowData);
  };

  const handleSalesPayinDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/payin.php?id=${companyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payin_id: id,
        }),
      });

      const responseData = await response.json();
      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSalesReturnEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleExpancesCategoryEdit = (rowData) => {
    handleEditClick(rowData);
  };
  const handleExpancesCategoryTransactionEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleExpancesCategoryDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/expensecategory.php?id=${companyId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            delete_category_id: id,
          }),
        }
      );

      const responseData = await response.json();
      console.log(responseData);
      if (responseData.status === 200) {
        onDelete();
        window.location.reload();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleExpancesItemEdit = (rowData) => {
    handleEditClick(rowData);
  };
  const handleItemTransactionEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleExpancesItemDeleteClick = async (id, onDelete) => {
    try {
      console.log(id);
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/expenseitem.php?id=${companyId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            expenses_item_id: id,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.status === 200) {
        onDelete();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlePurvhasePayoutEditClick = (rowData) => {
    handleEditClick(rowData);
  };

  const handleCompanEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleCompanyViewClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleCompanyDeleteClick = async (id) => {
    console.log(id);
    try {
      const response = await fetch(
        "https://client.barbikan.in/orchids/api/src/plan.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            delete_plan_id: id,
          }),
        }
      );

      const responseData = await response.json();

      console.log(responseData);

      if (responseData.head.code === 200) {
        navigate("/console/plan");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleRowClick = (rowData) => {
    onClick(rowData);
  };
  console.log("pg", pageview);
  return (
    <>
      {pageview === "yes" && pageview !== undefined ? (
        <div className="text-end">
          <span className="mx-1">
            Page {currentPage} of {totalPages}
          </span>
          <span className="mx-1">
            <Buttons
              lable={
                <>
                  <MdChevronLeft />
                </>
              }
              onClick={prevPage}
              disabled={currentPage === 1}
            />
          </span>
          <span className="mx-1">
            <Buttons
              lable={
                <>
                  <MdChevronRight />
                </>
              }
              onClick={nextPage}
              disabled={currentPage === totalPages}
            />
          </span>
        </div>
      ) : (
        " "
      )}
      <Table responsive="md" style={style}>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {type === "USER" && (
                <>
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.name}</td>
                  <td>{rowData.phone_number}</td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleUserEdit(rowData)}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleUserDeleteClick(rowData.user_id, onDelete)
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "company" && (
                <>
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.company_name}</td>
                  <td>{rowData.gst_no}</td>
                  <td>{rowData.mobile_number}</td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleCompanyEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "partypurches" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td onClick={() => handleRowClick(rowData)}>
                    {rowData.party_name}
                  </td>
                  {/* <td onClick={() => handleRowClick(rowData)}>
                    {rowData.ac_type}
                  </td> */}
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handlePartyPurchaseEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handlePartyPurchaseDeleteClick(
                              rowData.party_id,
                              onDelete
                            )
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "partypurchestransactions" && (
                <>
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.type}</td>
                  <td>{rowData.date}</td>
                  <td>{rowData.receipt_no}</td>
                  <td>{rowData.amount}</td>
                  <td>{rowData.paid}</td>
                  <td>{rowData.balance}</td>
                  {/* <td className='text-end'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handlePartyPurchaseTransactionEditClick(rowData)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td> */}
                </>
              )}
              {type === "partysales" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td onClick={() => handleRowClick(rowData)}>
                    {rowData.party_name}
                  </td>
                  {/* <td onClick={() => handleRowClick(rowData)}>
                    {rowData.opening_balance}
                  </td> */}
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handlePartySalesEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handlePartySalesDeleteClick(
                              rowData.party_id,
                              onDelete
                            )
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "partysalestransactions" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.type}</td>
                  <td>{rowData.date}</td>
                  <td>{rowData.receipt_no}</td>
                  <td>{rowData.amount}</td>
                  <td>{rowData.paid}</td>
                  <td>{rowData.balance}</td>
                  {/* <td className='text-end'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handlePartySalesTransactionEditClick(rowData)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td> */}
                </>
              )}
              {type === "itemsproduct" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td onClick={() => handleRowClick(rowData)}>
                    {rowData.product_name}
                  </td>
                  <td onClick={() => handleRowClick(rowData)}>
                    {rowData.unit_rate}
                  </td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleItemProdutEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleItemProductDeleteClick(
                              rowData.product_id,
                              onDelete
                            )
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "itemsproducttransactions" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.type}</td>
                  <td>{rowData.date}</td>
                  <td>{rowData.receipt_no}</td>
                  <td>{rowData.amount}</td>
                  {/* <td className='text-end'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleItemProdutTransactionEditClick(rowData)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td> */}
                </>
              )}
              {type === "itemscategory" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td onClick={() => handleRowClick(rowData)}>
                    {rowData.category_name}
                  </td>
                  <td onClick={() => handleRowClick(rowData)}>
                    {rowData.product_count}
                  </td>
                  <td className="">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleItemCategoryEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleItemCategoryDeleteClick(
                              rowData.category_id,
                              onDelete
                            )
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "itemscategorytransactions" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.product_name}</td>
                  <td className="text-end">{rowData.current_stock}</td>
                  {/* <td className='text-end'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleItemCategoryTransactionEditClick(rowData)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td> */}
                </>
              )}
              {type === "itemsunit" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td onClick={() => handleRowClick(rowData)}>
                    {rowData.unit_name}
                  </td>
                  <td className="">
                    <Dropdown className="text-end">
                      <Dropdown.Toggle>
                        <Button className="action ">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleItemUnitEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleItemUnitDeleteClick(rowData.unit_id, onDelete)
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "itemsunitransactions" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  {/* <td>{rowData.sno}</td> */}
                  <td>
                    {" "}
                    1 {rowData.unit_name} = {rowData.rate}{" "}
                    {rowData.subunit_name}
                  </td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            handleItemUnitTransactionEditClick(rowData)
                          }
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleCompanyDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "purchasebill" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.bill_date}</td>
                  <td>{rowData.bill_no}</td>
                  <td>{rowData.party_details.party_name}</td>
                  <td>{rowData.total}</td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <PDFDownloadLink
                          document={<PurchaseReceipt data={rowData} />}
                          fileName="purchasebill.pdf"
                        >
                          {({ blob, url, loading, error }) => (
                            <a
                              className="dropdown-item"
                              role="button"
                              tabIndex="0"
                              href={url}
                            >
                              View Pdf
                            </a>
                          )}
                        </PDFDownloadLink>
                        <Dropdown.Item
                          onClick={() => handlePurchaseBillEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {/* <Dropdown.Item onClick={() => handlePurchaseBillPrintClick(rowData)}>Print</Dropdown.Item> */}
                        {/* <Dropdown.Item onClick={() => handlePurchaseBillDeleteClick(rowData.purchase_id, onDelete)}>Delete</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "purchasepayout" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.voucher_date}</td>
                  <td>{rowData.voucher_no}</td>
                  <td>{rowData.party_details.party_name}</td>
                  <td>{rowData.paid}</td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <PDFDownloadLink
                          document={<PayoutPdf data={rowData} />}
                          fileName="payout.pdf"
                        >
                          {({ blob, url, loading, error }) => (
                            <a
                              className="dropdown-item"
                              role="button"
                              tabIndex="0"
                              href={url}
                            >
                              View Pdf
                            </a>
                          )}
                        </PDFDownloadLink>
                        <Dropdown.Item
                          onClick={() => handlePurchasePayoutEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handlePurchasePayoutDeleteClick(
                              rowData.payout_id,
                              onDelete
                            )
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "purchasereturn" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.date}</td>
                  <td>{rowData.receipt_no}</td>
                  <td>{rowData.name}</td>
                  <td>{rowData.total}</td>
                  <td>{rowData.received}</td>
                  <td>{rowData.due}</td>
                  <td className="">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handlePurchaseReturnEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleCompanyDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "salesinvoice" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.bill_date}</td>
                  <td>{rowData.bill_no}</td>
                  <td>{rowData.party_details.party_name}</td>
                  <td>{rowData.total}</td>
                  <td>{rowData.paid}</td>
                  <td>{rowData.balance}</td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item onClick={() => handleSalesInvoiceEditClick(rowData)}>Edit</Dropdown.Item> */}
                        <Dropdown.Item
                          onClick={() => handleSalesInvoicePrintClick(rowData)}
                        >
                          Print
                        </Dropdown.Item>
                        {/* <Dropdown.Item onClick={() => handleSalesInvoiceDeleteClick(rowData.invoice_id, onDelete)}>Delete</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "salesestimate" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.estimate_date}</td>
                  <td>{rowData.estimate_no}</td>
                  <td>{rowData.party_details.party_name}</td>
                  <td>{rowData.total}</td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <PDFDownloadLink
                          document={<EstimationPdf data={rowData} />}
                          fileName="SalesEstimate.pdf"
                        >
                          {({ blob, url, loading, error }) => (
                            <a
                              className="dropdown-item"
                              role="button"
                              tabIndex="0"
                              href={url}
                            >
                              View Pdf
                            </a>
                          )}
                        </PDFDownloadLink>
                        {/* <Dropdown.Item onClick={() => handleSalesEstimateEditClick(rowData)}>Edit</Dropdown.Item> */}
                        {/* <Dropdown.Item onClick={() => handleSalesEstimatePrintClick(rowData)}>Print</Dropdown.Item> */}
                        {/* <Dropdown.Item onClick={() => handleSalesEstimateDeleteClick(rowData.estimate_id, onDelete)}>Delete</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "salespayin" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.receipt_date}</td>
                  <td>{rowData.receipt_no}</td>
                  <td>{rowData.party_details.party_name}</td>
                  <td>{rowData.paid}</td>
                  <td className="">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <PDFDownloadLink
                          document={<PayInPdf data={rowData} />}
                          fileName="payin.pdf"
                        >
                          {({ blob, url, loading, error }) => (
                            <a
                              className="dropdown-item"
                              role="button"
                              tabIndex="0"
                              href={url}
                            >
                              View Pdf
                            </a>
                          )}
                        </PDFDownloadLink>
                        <Dropdown.Item
                          onClick={() => handleSalesPayinEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleSalesPayinDeleteClick(
                              rowData.payin_id,
                              onDelete
                            )
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "salesreturn" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.date}</td>
                  <td>{rowData.receipt_no}</td>
                  <td>{rowData.name}</td>
                  <td>{rowData.total}</td>
                  <td>{rowData.paid}</td>
                  <td>{rowData.due}</td>
                  <td className="">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleSalesReturnEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleCompanyDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "expensescategory" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td onClick={() => handleRowClick(rowData)}>
                    {rowData.category_name}
                  </td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleExpancesCategoryEdit(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleExpancesCategoryDeleteClick(
                              rowData.category_id,
                              onDelete
                            )
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "expensescategorytransactions" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.date}</td>
                  <td>{rowData.receipt_no}</td>
                  <td>{rowData.party_name}</td>
                  <td>{rowData.amount}</td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            handleExpancesCategoryTransactionEditClick(rowData)
                          }
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleCompanyDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "expensesitems" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td onClick={() => handleRowClick(rowData)}>
                    {rowData.party_name}
                  </td>
                  <td onClick={() => handleRowClick(rowData)}>
                    {rowData.total}
                  </td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleExpancesItemEdit(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleExpancesItemDeleteClick(
                              rowData.expenses_item_id,
                              onDelete
                            )
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "expensesitemtransactions" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{rowIndex + 1}</td>
                  <td>{rowData.date}</td>
                  <td>{rowData.receipt_no}</td>
                  <td>{rowData.party_name}</td>
                  <td>{rowData.amount}</td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            handleItemTransactionEditClick(rowData)
                          }
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleCompanyDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {pageview === "yes" && pageview !== undefined ? (
        <div className="text-end">
          <span className="mx-1">
            Page {currentPage} of {totalPages}
          </span>
          <span className="mx-1">
            <Buttons
              lable={
                <>
                  <MdChevronLeft />
                </>
              }
              onClick={prevPage}
              disabled={currentPage === 1}
            />
          </span>
          <span className="mx-1">
            <Buttons
              lable={
                <>
                  <MdChevronRight />
                </>
              }
              onClick={nextPage}
              disabled={currentPage === totalPages}
            />
          </span>
        </div>
      ) : (
        " "
      )}
    </>
  );
};
export { Tables, ProductTable, TableUI };
