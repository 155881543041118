import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import PayInPdf from "./PayInPdf";
import API_DOMAIN from "../config/config";
const PayInPreview = () => (
  <PDFViewer height={"1000"} width={"100%"}>
    <PayInPdf />
  </PDFViewer>
);

export default PayInPreview;
