import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Modal, Tab, Tabs } from "react-bootstrap";
import {
  Calender,
  DropDown,
  TextInputForm,
  DropDownUI,
} from "../components/Forms";
import { PageHead } from "../components/PageTitle";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate, Navigate } from "react-router-dom";
import {
  Buttons,
  Dltbtn,
  ClickButton,
  InstantCreate,
  Delete,
} from "../components/Buttons";
import { BiPlus } from "react-icons/bi";
import API_DOMAIN from "../config/config";

import PropagateLoader from "react-spinners/PropagateLoader";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import moment from "moment/moment";
// PurchaseBillEntry start
const alltax = [
  {
    value: "IGST@0%",
    label: "IGST@0%",
  },
  {
    value: "GST@0%",
    label: "GST@0%",
  },
  {
    value: "IGST@0.25%",
    label: "IGST@0.25%",
  },
  {
    value: "GST@0.25%",
    label: "GST@0.25%",
  },
  {
    value: "IGST@3%",
    label: "IGST@3%",
  },
  {
    value: "GST@3%",
    label: "GST@3%",
  },
  {
    value: "IGST@5%",
    label: "IGST@5%",
  },
  {
    value: "GST@5%",
    label: "GST@5%",
  },
  {
    value: "IGST@12%",
    label: "IGST@12%",
  },
  {
    value: "GST@12%",
    label: "GST@12%",
  },
  {
    value: "IGST@18%",
    label: "IGST@18%",
  },
  {
    value: "GST@18%",
    label: "GST@18%",
  },
  {
    value: "IGST@28%",
    label: "IGST@28%",
  },
  {
    value: "GST@28%",
    label: "GST@28%",
  },
];
const allovertax = [
  {
    value: "IGST@0%",
    label: "IGST@0%",
  },
  {
    value: "GST@0%",
    label: "GST@0%",
  },
  {
    value: "IGST@0.25%",
    label: "IGST@0.25%",
  },
  {
    value: "GST@0.25%",
    label: "GST@0.25%",
  },
  {
    value: "IGST@3%",
    label: "IGST@3%",
  },
  {
    value: "GST@3%",
    label: "GST@3%",
  },
  {
    value: "IGST@5%",
    label: "IGST@5%",
  },
  {
    value: "GST@5%",
    label: "GST@5%",
  },
  {
    value: "IGST@12%",
    label: "IGST@12%",
  },
  {
    value: "GST@12%",
    label: "GST@12%",
  },
  {
    value: "IGST@18%",
    label: "IGST@18%",
  },
  {
    value: "GST@18%",
    label: "GST@18%",
  },
  {
    value: "IGST@28%",
    label: "IGST@28%",
  },
  {
    value: "GST@28%",
    label: "GST@28%",
  },
];

const DropList = [
  {
    value: "DR",
    label: "DR",
  },
  {
    value: "CR",
    label: "CR",
  },
];
const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const PurchaseBillEntry = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const navigate = useNavigate();
  const [tax_type, settax_type] = useState("");
  console.log("taxwork", tax_type);
  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          party_id: "",
          bill_no: "",
          bill_date: getCurrentDate(), // Initialize with the current date
          stock_date: getCurrentDate(),

          purchase_gst: "",
          purchasemobile_no: "",
          total: "",
          paid: 0,
          balance_amount: "",
          final_total: "",
          discount_all: "",
          total_discountall: "",
          taxover_amt: "",
          taxover_percentage: "",
          round_off_checkbox: "",
          product: [
            {
              product_id: "",
              qty: "",
              unit: "",
              price_unit: "",
              discount_percentage: "",
              discount_amt: "",
              tax_type: "",
              tax_percentage: "",
              tax_amt: "",
              total_amt: "",
            },
          ],
        };
  const [formData, setFormData] = useState({
    ...initialState,
    bill_date: new Date(), // or an empty string if you want to handle it conditionally
    stock_date: new Date(),
  });
  const formatDateString = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  console.log("formData", formData);
  const setLabel = (date, type) => {
    if (date instanceof Date && !isNaN(date)) {
      setFormData((prev) => ({
        ...prev,
        [type]: formatDateString(date),
      }));
    } else {
      console.error("Invalid date format received");
    }
  };

  const handleAddRow = () => {
    setFormData((prevState) => ({
      ...prevState,
      product: [
        ...prevState.product,
        {
          /* New product row */
        },
      ],
    }));
  };

  // Delete a row from the product list
  const handleDeleteRow = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      product: prevState.product.filter((_, i) => i !== index),
    }));
  };

  // Handle change in total amount
  const handleTotalChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      total: e.target.value,
    }));
  };

  // Handle change in paid amount
  const handlePaidChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      paid: e.target.value,
    }));
  };
  const calculateTotal = (product) => {
    const totalAmount = product.reduce(
      (acc, row) => acc + parseFloat(row.total_amt || 0),
      0
    );

    setFormData((prevData) => ({
      ...prevData,
      total: totalAmount.toFixed(2),
    }));
  };
  // Handle key press event
  const handleKeyPress = (event, index) => {
    if (event.key === "Enter") {
      const lastRow = formData.product[formData.product.length - 1];
      if (
        lastRow.product_id &&
        lastRow.qty &&
        lastRow.unit &&
        lastRow.price_unit &&
        lastRow.total_amt
      ) {
        handleAddRow();
      } else {
        toast.error(
          "Please fill in all fields in the last row before adding a new row"
        );
      }
    }
  };

  const handleChange = (e, fieldName, rowIndex) => {
    const value = e.target ? e.target.value : e.value;
    var updatedFormData = { ...formData };

    if (rowIndex !== undefined) {
      updatedFormData = {
        ...formData,
        product: formData.product.map((row, index) =>
          index === rowIndex ? { ...row, [fieldName]: value } : row
        ),
      };
    } else {
      updatedFormData = {
        ...formData,
        [fieldName]: value,
      };
    }

    updatedFormData.product.forEach((row, index) => {
      const qty = parseFloat(row.qty);
      const price = parseFloat(row.price_unit);
      const discountPercentage = parseFloat(row.discount_percentage);
      const discountInputAmount = parseFloat(row.discount_amt);
      const taxPercentage = parseFloat(row.tax_percentage);
      const taxtype = row.tax_type;
      let sgst = 0,
        cgst = 0,
        igst = 0;

      let crtdiscountFiled = 0.0;

      let subtotal = isNaN(qty) || isNaN(price) ? 0 : qty * price;
      if (fieldName === "price_unit") {
        crtdiscountFiled = isNaN(discountPercentage)
          ? 0
          : isNaN(subtotal)
          ? 0
          : (subtotal * discountPercentage) / 100;
        updatedFormData.product[index].discount_amt = crtdiscountFiled;
      }
      if (fieldName === "discount_percentage") {
        crtdiscountFiled = isNaN(discountPercentage)
          ? 0
          : isNaN(subtotal)
          ? 0
          : (subtotal * discountPercentage) / 100;
        updatedFormData.product[index].discount_amt = crtdiscountFiled;
      } else if (fieldName === "discount_amt") {
        crtdiscountFiled = isNaN(discountInputAmount) ? 0 : discountInputAmount;
        const cal = (crtdiscountFiled / subtotal) * 100;
        updatedFormData.product[index].discount_percentage = isNaN(cal)
          ? 0
          : parseFloat(cal).toFixed(2);
      }
      let tmpsubtotal = 0;
      if (fieldName === "tax_percentage") {
        tmpsubtotal =
          subtotal - (isNaN(discountInputAmount) ? 0 : discountInputAmount);
      } else {
        tmpsubtotal =
          subtotal - (isNaN(crtdiscountFiled) ? 0 : crtdiscountFiled);
      }

      console.log("siva", tmpsubtotal);
      console.log("siva tax", taxPercentage);
      console.log("siva disper", discountPercentage);
      console.log("discountInputAmount", discountInputAmount);
      console.log("subtotal", subtotal);
      console.log("crtdiscountFiled", crtdiscountFiled);
      let taxAmount =
        isNaN(tmpsubtotal) || isNaN(taxPercentage)
          ? 0
          : (tmpsubtotal * taxPercentage) / 100;
      let finalAmount = tmpsubtotal + taxAmount;
      if (taxtype === "IGST") {
        igst = taxAmount;
      } else {
        var gstamount = taxAmount / 2;
        cgst = gstamount;
        sgst = gstamount;
      }

      updatedFormData.product[index].tax_amt = taxAmount.toFixed(2);
      updatedFormData.product[index].total_amt = finalAmount.toFixed(2);
    });

    const totalAmount = updatedFormData.product.reduce(
      (acc, row) => acc + parseFloat(row.total_amt || 0),
      0
    );
    updatedFormData.total = totalAmount.toFixed(2);

    setFormData(updatedFormData);
  };

  function calculateTax(product, tax_percentage, index, taxType) {
    let updatedFormData = { ...formData };
    let sgst = 0,
      cgst = 0,
      igst = 0;
    console.log("sivabbbb", product);
    // Calculate tax amount based on tax type
    // let taxAmount = parseFloat(product.tax_amt);
    let producttotal = parseFloat(product.total_amt);
    console.log("sivaproducttotaltbbb", producttotal);
    console.log("tax_percentage", tax_percentage);
    let taxAmt =
      (producttotal * parseFloat(tax_percentage.match(/\d+\.*\d*/)[0])) / 100;
    console.log("taxamt", taxAmt);
    console.log("sivataxAmountbbb", taxAmt);
    if (taxType === "GST") {
      var taxAmount = taxAmt / 2;
      sgst = taxAmount;
      cgst = taxAmount;
    } else if (taxType === "IGST") {
      igst = taxAmt;
    }

    // Update form data with tax amounts
    updatedFormData.product[index].SGST = sgst.toFixed(2);
    updatedFormData.product[index].CGST = cgst.toFixed(2);
    updatedFormData.product[index].IGST = igst.toFixed(2);
    updatedFormData.product[index].tax_type = taxType;

    setFormData(updatedFormData);
  }
  useEffect(() => {
    calculateTotal(formData.product);
  }, [formData.product]);
  useEffect(() => {
    const updateBalance = () => {
      const total = parseFloat(formData.total);
      const paid = parseFloat(formData.paid);

      const balance = !isNaN(total) && !isNaN(paid) ? total - paid : "";

      setFormData((prevFormData) => ({
        ...prevFormData,
        balance_amount: balance.toString(),
      }));
    };

    updateBalance();
  }, [formData.total, formData.paid]);

  console.log(formData);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const companyId = localStorage.getItem("companyId");
        const response = await fetch(
          `${API_DOMAIN}/products.php?id=${companyId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              search_text: "",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        setLoading(responseData);
        console.log(responseData);
        if (responseData.status === 200) {
          setProductData(responseData.data);
        } else {
          throw new Error(responseData.msg);
        }
      } catch (error) {
        setLoading();
        console.error("Error fetching data:", error.message);
      }
    };

    fetchProductData(); // Call fetchData directly in useEffect
  }, []);
  const [UnitData, setUnitData] = useState([]);
  const fetchUnitData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/unit.php?id=${companyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setUnitData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  const [purchseParty, setPurchaseparty] = useState([]);
  const fetchDatapurchseParty = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/purchase_party.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          company_id: companyId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("siva", responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setPurchaseparty(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchUnitData(); // Call fetchData directly in useEffect
    fetchDatapurchseParty();
  }, []);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleSubmit = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/purchase_bill.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      console.log("formData12", JSON.stringify(formData));
      const responseData = await response.json();

      //  console.log(responseData);

      if (responseData.status === 200) {
        setFormData({
          party_id: "",
          bill_no: "",
          bill_date: "",
          total: "",
          paid: "",
          balance_amount: "",
          final_total: "",
          discount_all: "",
          purchasemobile_no: "",
          purchase_gst: "",
          total_discountall: "",
          taxover_amt: "",
          taxover_percentage: "",
          round_off_checkbox: "",
          product: [
            {
              product_id: "",
              qty: "",
              unit: "",
              price_unit: "",
              discount_percentage: "",
              discount_amt: "",
              tax_percentage: "",
              tax_amt: "",
              total_amt: "",
            },
          ],
        });
        successAlert(responseData.msg);
        setTimeout(() => {
          navigate("/console/purchase/bill");
        }, 2000);
      } else if (responseData.status === 400) {
        toast.error("Missing required fields!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const companyId = localStorage.getItem("companyId");

      // Format date fields
      const formattedFormData = {
        ...formData,
        bill_date: formData.bill_date.toISOString().split("T")[0],
        stock_date: formData.stock_date.toISOString().split("T")[0],
      };

      const response = await fetch(
        `${API_DOMAIN}/purchase_bill.php?id=${companyId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formattedFormData),
        }
      );

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        toast.success("PurchaseBill updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/purchase/bill");
        }, 2000);
      } else {
        console.error(
          responseData.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating product:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    });
  });
  useEffect(() => {
    // Check if party_id is selected
    if (formData.party_id) {
      // Find the selected party in partySalesData
      const selectedParty = purchseParty.find(
        (party) => party.party_id === formData.party_id
      );

      if (selectedParty) {
        // Update formData with party data
        setFormData((prevFormData) => ({
          ...prevFormData,
          purchasemobile_no: selectedParty.mobile_number || "",
          purchase_gst: selectedParty.gst_no || "",
        }));
      }
    }
  }, [formData.party_id, purchseParty]);

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem("session");
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking session:", error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return (
    <>
      <div>
        <div className="main stick-top">
          <Container fluid>
            <Row>
              <Col lg="12">
                <PageHead
                  pagehead={<>Purchase Bill</>}
                  onClick={() => navigate(-1)}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="main my-3">
          <div>
            <Container fluid>
              <Row>
                <Col lg="12">
                  <Table>
                    <tr>
                      <td>
                        <div className="d-flex">
                          <div className="w-25 m-3">
                            <DropDownUI
                              optionlist={purchseParty.map((user) => ({
                                value: user.party_id,
                                label: user.party_name,
                              }))}
                              placeholder="Party Name"
                              labelname="Party Name"
                              name="party_id"
                              value={formData.party_id}
                              onChange={(updatedformData) =>
                                setFormData({
                                  ...formData,
                                  party_id: updatedformData.party_id,
                                })
                              }
                            />
                          </div>
                          <div className="w-25 m-3">
                            <TextInputForm
                              placeholder={"PartyGST"}
                              labelname={"PartyGST"}
                              name="purchase_gst"
                              value={formData.purchase_gst}
                              onChange={(e) => handleChange(e, "purchase_gst")}
                            />
                          </div>
                        </div>
                        <div className="w-25 m-3">
                          <TextInputForm
                            placeholder={"PartyMobileNumber"}
                            labelname={"PartyMobileNumber"}
                            name="purchasemobile_no"
                            value={formData.purchasemobile_no}
                            onChange={(e) =>
                              handleChange(e, "purchasemobile_no")
                            }
                          />
                        </div>
                      </td>
                      <td className="mx-auto float-end">
                        <div>
                          <span>
                            <TextInputForm
                              placeholder={"Bill Number"}
                              labelname={"Bill Number"}
                              name="bill_no"
                              value={formData.bill_no}
                              onChange={(e) => handleChange(e, "bill_no")}
                            />
                          </span>
                        </div>
                        <div>
                          <div className="text-left"></div>
                          <Calender
                            setLabel={(date) => setLabel(date, "bill_date")}
                            selectedDate={formData.bill_date}
                            calenderlabel="BillDate"
                          />
                        </div>
                        <div>
                          <div className="text-left"></div>
                          <Calender
                            setLabel={(date) => setLabel(date, "stock_date")}
                            selectedDate={formData.stock_date}
                            calenderlabel="StockDate"
                          />
                        </div>
                      </td>
                    </tr>
                  </Table>
                </Col>
                <Col lg="12" className="py-3">
                  <div>
                    No Of Products : <span>{formData.product.length}</span>
                  </div>
                </Col>
                <Col lg="12" md="6" xs="12">
                  <div className="py-2 category-table bill">
                    <Table>
                      <thead className="">
                        <tr>
                          <th rowSpan={2}>#</th>
                          <th rowSpan={2} className="wrdbrk w-20">
                            Product Name
                          </th>
                          <th rowSpan={2} className="wrdbrk w-7">
                            Qty
                          </th>
                          <th rowSpan={2} className="wrdbrk w-10">
                            Unit
                          </th>
                          <th rowSpan={2} className="wrdbrk w-7">
                            Price / Unit
                          </th>
                          <th colSpan={2} className="wrdbrk w-15">
                            Discount
                          </th>
                          <th colSpan={2} className="wrdbrk w-15">
                            Tax
                          </th>

                          <th rowSpan={2} className="wrdbrk">
                            Amount
                          </th>

                          <th rowSpan={2} className="wrdbrk"></th>
                        </tr>
                        <tr>
                          <th>%</th>
                          <th className="w-10">Amount</th>
                          <th>%</th>
                          <th className="w-10">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData.product &&
                          formData.product.map((row, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <div>
                                  <DropDownUI
                                    optionlist={productData.map((user) => ({
                                      value: user.product_id,
                                      label: user.product_name,
                                    }))}
                                    placeholder="ProductName"
                                    name="product_id"
                                    value={row.product_id}
                                    onChange={(updatedFormData) =>
                                      setFormData({
                                        ...formData,
                                        product: formData.product.map((r, i) =>
                                          i === index
                                            ? {
                                                ...r,
                                                product_id:
                                                  updatedFormData.product_id,
                                              }
                                            : r
                                        ),
                                      })
                                    }
                                    onKeyDown={(e) => handleKeyPress(e, index)}
                                    autoFocus={
                                      index === formData.product.length - 1
                                    }
                                  />
                                </div>
                                <div>
                                  <TextInputForm placeholder={"Remarks"} />
                                </div>
                              </td>
                              <td>
                                <TextInputForm
                                  placeholder={"qty"}
                                  name="qty"
                                  value={row.qty}
                                  onChange={(e) =>
                                    handleChange(e, "qty", index)
                                  }
                                  onKeyDown={(e) => handleKeyPress(e, index)}
                                ></TextInputForm>
                              </td>
                              <td>
                                <DropDownUI
                                  optionlist={UnitData.map((user) => ({
                                    value: user.unit_id,
                                    label: user.unit_name,
                                  }))}
                                  placeholder="Unit"
                                  name="unit"
                                  value={row.unit}
                                  onChange={(updatedFormData) =>
                                    setFormData({
                                      ...formData,
                                      product: formData.product.map((r, i) =>
                                        i === index
                                          ? { ...r, unit: updatedFormData.unit }
                                          : r
                                      ),
                                    })
                                  }
                                  onKeyDown={(e) => handleKeyPress(e, index)}
                                />
                              </td>
                              <td>
                                <TextInputForm
                                  placeholder={"price"}
                                  name="price"
                                  value={row.price_unit}
                                  onChange={(e) =>
                                    handleChange(e, "price_unit", index)
                                  }
                                  onKeyDown={(e) => handleKeyPress(e, index)}
                                />
                              </td>
                              <td>
                                <TextInputForm
                                  placeholder={"discount_percentage"}
                                  name="discount_percentage"
                                  value={row.discount_percentage}
                                  onChange={(e) =>
                                    handleChange(
                                      e,
                                      "discount_percentage",
                                      index
                                    )
                                  }
                                  onKeyDown={(e) => handleKeyPress(e, index)}
                                  disabled={formData.discount_all}
                                />
                              </td>
                              <td>
                                <TextInputForm
                                  placeholder={"discount_amt"}
                                  name="discount_amt"
                                  value={row.discount_amt}
                                  onChange={(e) =>
                                    handleChange(e, "discount_amt", index)
                                  }
                                  onKeyDown={(e) => handleKeyPress(e, index)}
                                  disabled={formData.total_discountall}
                                />
                              </td>

                              <td className="w-10 text-left">
                                <DropDownUI
                                  optionlist={alltax}
                                  placeholder="Tax"
                                  name="tax_percentage"
                                  value={row.tax_percentage}
                                  onChange={(updatedFormData) => {
                                    const selectedTax = alltax.find(
                                      (tax) =>
                                        tax.value ===
                                        updatedFormData.tax_percentage
                                    );
                                    console.log("selecedtax", selectedTax);
                                    const taxType = selectedTax
                                      ? selectedTax.value.split("@")[0]
                                      : "";
                                    console.log("taxtype", taxType);
                                    settax_type(taxType);
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      product: prevFormData.product.map(
                                        (r, i) =>
                                          i === index
                                            ? {
                                                ...r,
                                                tax_percentage:
                                                  updatedFormData.tax_percentage,
                                              }
                                            : r
                                      ),
                                    }));
                                    calculateTax(
                                      formData.product[index],
                                      updatedFormData.tax_percentage,
                                      index,
                                      taxType
                                    );
                                    handleChange(
                                      {
                                        value: parseFloat(
                                          updatedFormData.tax_percentage.match(
                                            /\d+\.*\d*/
                                          )[0]
                                        ),
                                      },
                                      "tax_percentage",
                                      index
                                    );
                                    //handleChange({ value: updatedFormData.taxType}, 'tax_type', index);
                                  }}
                                  onKeyDown={(e) => handleKeyPress(e, index)}
                                />
                              </td>
                              <td>
                                <TextInputForm
                                  placeholder={"tax_amt"}
                                  name="tax_amt"
                                  value={row.tax_amt}
                                  onChange={(e) =>
                                    handleChange(e, "tax_amt", index)
                                  }
                                  onKeyDown={(e) => handleKeyPress(e, index)}
                                />
                              </td>

                              <td>
                                <TextInputForm
                                  placeholder={"amt"}
                                  name="total_amt"
                                  value={row.total_amt}
                                  onChange={(e) =>
                                    handleChange(e, "total_amt", index)
                                  }
                                  onKeyDown={(e) => handleKeyPress(e, index)}
                                />
                              </td>

                              <td className="d-none">
                                <div className="d-none">
                                  {index === formData.product.length - 1 && (
                                    <ClickButton
                                      label={<>Add More</>}
                                      onClick={handleAddRow}
                                    />
                                  )}
                                </div>
                              </td>
                              <td>
                                <Delete
                                  onClick={() => handleDeleteRow(index)}
                                  label={<MdDeleteForever />}
                                ></Delete>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="my-3">
            <Container>
              <Row>
                <Col lg="5">{/* <PayMents /> */}</Col>
                <Col lg="7">
                  <div className="inside my-3">
                    <Table>
                      <tr>
                        <td>Total</td>
                        <td></td>
                        <td className="w-25">
                          <TextInputForm
                            placeholder={"Total"}
                            name="total"
                            value={formData.total}
                            onChange={handlePaidChange}
                          />
                        </td>
                      </tr>

                      <tr></tr>
                      <tr>
                        <td>Paid</td>
                        <td></td>
                        <td className="w-25">
                          <TextInputForm
                            placeholder={"Paid"}
                            name="paid"
                            value={formData.paid}
                            onChange={handlePaidChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Balance</td>
                        <td></td>
                        <td className="w-25">
                          <TextInputForm
                            placeholder={"balance"}
                            name="balance_amount"
                            value={formData.balance_amount}
                            onChange={handlePaidChange}
                          />
                        </td>
                      </tr>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="main stick-bottom">
          <div className="w-50 ms-auto">
            <Container>
              <Row>
                <Col lg="12">
                  <div className="text-center">
                    {type === "edit" ? (
                      <>
                        <ToastContainer
                          position="bottom-center"
                          autoClose={3000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="light"
                        />
                        <span className="mx-2">
                          <ClickButton
                            label={<>Update</>}
                            onClick={handleUpdateSubmit}
                          ></ClickButton>
                        </span>

                        <span className="mx-2">
                          <ClickButton
                            label={<>Cancel</>}
                            onClick={() => navigate("/console/purchase/bill")}
                          ></ClickButton>
                        </span>
                      </>
                    ) : (
                      <>
                        <ToastContainer
                          position="bottom-center"
                          autoClose={3000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="light"
                        />
                        <span className="mx-2">
                          <ClickButton
                            label={<>Submit</>}
                            onClick={handleSubmit}
                          ></ClickButton>
                        </span>

                        <span className="mx-2">
                          <ClickButton
                            label={<>Cancel</>}
                            onClick={() => navigate("/console/purchase/bill")}
                          ></ClickButton>
                        </span>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

// const PurchaseBillEntry = () => {
//   const location = useLocation();
//   const { type, rowData } = location.state || {};
//   const navigate = useNavigate();

//   const initialState = type === 'edit' ? { ...rowData } : {
//     party_id: '',
//     bill_no: '',
//     bill_date: '',
//     total: '',
//     paid: '',
//     balance_amount: '',
//     final_total: '',
//     discount_all: '',
//     total_discountall: '',
//     taxover_amt: '',
//     taxover_percentage: '',
//     round_off_checkbox: '',
//     product: [{ product_id: '', qty: '', unit: '', price_unit: '', discount_percentage: '', discount_amt: '', tax_percentage: '', tax_amt: '', total_amt: '' }]

//   };
//   const [formData, setFormData] = useState(initialState);

//   const handleChange = (e, fieldName) => {
//     const value = e.target ? e.target.value : e.value;

//     setFormData({
//       ...formData,
//       [fieldName]: value
//     });
//   };
//   const setLabel = (value, field) => {
//     setFormData({
//       ...formData,
//       [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
//     });
//   };
//   const [loading, setLoading] = useState(false);
//   const [showAlert, setShowAlert] = useState(false);
//   const [productData, setProductData] = useState([]);
//   useEffect(() => {
//     const fetchProductData = async () => {
//       try {
//         const companyId = localStorage.getItem('companyId');
//         const response = await fetch('https://node.zentexus.com/product/list/' + companyId, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({
//             search_text: ''
//           })
//         });

//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }

//         const responseData = await response.json();
//         setLoading(responseData);
//         console.log(responseData)
//         if (responseData.status === 200) {
//           setProductData(responseData.data);
//         } else {
//           throw new Error(responseData.msg);
//         }
//       } catch (error) {
//         setLoading();
//         console.error('Error fetching data:', error.message);
//       }
//     };

//     fetchProductData(); // Call fetchData directly in useEffect

//   }, []);
//   const [UnitData, setUnitData] = useState([])
//   const fetchUnitData = async () => {
//     try {
//       const companyId = localStorage.getItem('companyId');
//       const response = await fetch('https://node.zentexus.com/unit/list/' + companyId, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           search_text: ''
//         })
//       });
//       if (!response.ok) {
//         throw new Error('Failed to fetch data');
//       }

//       const responseData = await response.json();
//       console.log(responseData);
//       setLoading(false);
//       if (responseData.status === 200) {
//         setUnitData(responseData.data);

//       } else {
//         throw new Error(responseData.msg);
//       }
//     } catch (error) {
//       setLoading(false);
//       console.error('Error fetching data:', error.message);
//     }
//   };
//   const [purchseParty, setPurchaseparty] = useState([])
//   const fetchDatapurchseParty = async () => {
//     try {
//       const companyId = localStorage.getItem('companyId');
//       const response = await fetch('https://node.zentexus.com/purchaseparty/list/' + companyId, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           search_text: ''
//         })
//       });

//       if (!response.ok) {
//         throw new Error('Failed to fetch data');
//       }

//       const responseData = await response.json();
//       console.log('siva', responseData);
//       setLoading(false);
//       if (responseData.status === 200) {
//         setPurchaseparty(responseData.data);

//       } else {
//         throw new Error(responseData.msg);
//       }
//     } catch (error) {
//       setLoading(false);
//       console.error('Error fetching data:', error.message);
//     }
//   };
//   useEffect(() => {
//     fetchUnitData(); // Call fetchData directly in useEffect
//     fetchDatapurchseParty();
//   }, []);
//   const errorAlert = (input) => {
//     toast.error(input, {
//       position: "top-center",
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "colored",
//     });
//   }
//   const successAlert = (success) => {
//     toast.success(success, {
//       position: "top-center",
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "colored",

//     });
//   }
//   const handleSubmit = async () => {
//     try {

//       const companyId = localStorage.getItem('companyId');
//       const response = await fetch('https://node.zentexus.com/purchasebill/create/' + companyId, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(formData)
//       });
//       console.log(formData);
//       const responseData = await response.json();

//       console.log(responseData);

//       if (responseData.status === 200) {
//         setFormData({
//           party_id: '',
//           bill_no: '',
//           bill_date: '',
//           total: '',
//           paid: '',
//           balance_amount: '',
//           final_total: '',
//           discount_all: '',
//           total_discountall: '',
//           taxover_amt: '',
//           taxover_percentage: '',
//           round_off_checkbox: '',
//           product: [{ product_id: '', qty: '', unit: '', price_unit: '', discount_percentage: '', discount_amt: '', tax_percentage: '', tax_amt: '', total_amt: '' }]

//         })
//         successAlert(responseData.msg)
//         setTimeout(() => {
//           navigate("/console/purchase/bill");
//         }, 2000);
//       }
//       else if (responseData.status === 400) {
//         toast.error('Missing required fields!', {
//           position: "top-center",
//           autoClose: 2000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       }
//       else {
//         setShowAlert(true);
//       }

//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };
//   const handleUpdateSubmit = async () => {
//     try {
//       const response = await fetch('https://node.zentexus.com/user/update/joyg67j8t689t', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ // Include the company ID in the request

//         }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update company');
//       }

//       const responseData = await response.json();
//       console.log(responseData);

//       if (responseData.status === 200) {
//         toast.success('User updated successfully!', {
//           position: "top-center",
//           autoClose: 2000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//         setTimeout(() => {
//           navigate("/console/purchase/bill/create");
//         }, 2000);

//         // Navigate to the user list page after a delay

//       } else {
//         console.error(responseData.msg || 'Unknown error occurred during update');
//       }
//     } catch (error) {
//       console.error('Error updating product:', error.msg);
//     }

//     setLoading(false);
//   };
//   useEffect(() => {
//     setLoading(true)

//     setTimeout(() => {
//       setLoading(false)
//     })
//   })

//   return (
//     <>

//       <div>
//         <div className='main stick-top'>
//           <Container fluid>
//             <Row>
//               <Col lg='12'><PageHead pagehead={<>Purchase Bill</>} onClick={() => navigate(-1)} /></Col>
//             </Row>
//           </Container>
//         </div>
//         <div className='main my-3'>
//           <div>
//             <Container fluid>
//               <Row>
//                 <Col lg='12'>
//                   <Table>
//                     <tr>
//                       <td>
//                         <div className='w-75'>
//                           <DropDownUI
//                             optionlist={purchseParty.map(user => ({
//                               value: user.party_id,
//                               label: user.party_name
//                             }))}
//                             placeholder='Party Name'
//                             labelname='Party Name'
//                             name='party_id'
//                             value={type === 'edit' ? formData.party_id : formData.party_id}
//                             onChange={(updatedformData) => setFormData({ ...formData, party_id: updatedformData.party_id })}
//                           />
//                         </div>
//                         <div className='instant-btn'>
//                           <InstantCreate label={<BiPlus />} className='instant-add'></InstantCreate>
//                         </div>
//                       </td>
//                       <td className='mx-auto float-end'>
//                         <div>
//                           <span>
//                             <TextInputForm placeholder={'Bill Number'}
//                               labelname={'Bill Number'}
//                               name='bill_no'
//                               value={type === 'edit' ? formData.bill_no : formData.bill_no}
//                               onChange={(e) => handleChange(e, 'bill_no')} />
//                           </span>
//                         </div>
//                         <div>
//                           <div className='text-left'>
//                           </div>
//                           <Calender setLabel={(date) => setLabel(date, 'bill_date')} selectedDate={type === 'edit' ? formData.bill_date : ''} calenderlabel="BillDate" />
//                         </div>
//                       </td>
//                     </tr>
//                   </Table>
//                 </Col>
//                 <Col lg='12' className='py-3'>
//                   <div>No Of Products : <span>1</span></div>
//                 </Col>
//                 <Col lg='12' md='6' xs='12'>
//                   <div className="py-2 category-table bill">
//                     <Table>
//                       <thead className=''>
//                         <tr>
//                           <th rowSpan={2}>#</th>
//                           <th rowSpan={2} className='wrdbrk w-20'>Product Name</th>
//                           <th rowSpan={2} className='wrdbrk w-7'>Qty</th>
//                           <th rowSpan={2} className='wrdbrk w-10'>Unit</th>
//                           <th rowSpan={2} className='wrdbrk w-7'>Price / Unit</th>
//                           <th colSpan={2} className='wrdbrk w-15'>Discount</th>
//                           <th colSpan={2} className='wrdbrk w-15' >Tax</th>
//                           <th rowSpan={2} className='wrdbrk'>Amount</th>
//                         </tr>
//                         <tr>
//                           <th>%</th>
//                           <th className='w-10'>Amount</th>
//                           <th >%</th>
//                           <th className='w-10'>Amount</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         <tr>
//                           <td>1</td>
//                           <td>
//                             <div>
//                               <DropDownUI
//                                 optionlist={productData.map(user => ({
//                                   value: user.product_id,
//                                   label: user.product_name
//                                 }))}
//                                 placeholder='ProductName'
//                                 name='product_id'
//                                 value={formData.product_id}
//                                 onChange={(updatedFormData) => {
//                                   setFormData({ ...formData, product_id: updatedFormData.product_id })
//                                 }}
//                               />
//                             </div>
//                             <div>
//                               <TextInputForm placeholder={"Remarks"} />
//                             </div>
//                           </td>
//                           <td>
//                             <TextInputForm
//                               placeholder={'qty'}
//                               name='qty'
//                               value={formData.qty}
//                               onChange={(e) => handleChange(e, 'qty')}
//                             ></TextInputForm>
//                           </td>
//                           <td>
//                             <DropDownUI
//                               optionlist={UnitData.map(user => ({
//                                 value: user.unit_id,
//                                 label: user.unit_name
//                               }))}
//                               placeholder='Unit'
//                               name='unit'
//                               value={formData.unit}
//                               onChange={(updatedFormData) => {
//                                 setFormData({ ...formData, unit: updatedFormData.unit })
//                               }} />
//                           </td>
//                           <td>
//                             <TextInputForm
//                               placeholder={'price'}
//                               name='price'
//                               value={formData.price_unit}
//                               onChange={(e) => handleChange(e, 'price_unit')}
//                             />

//                           </td>
//                           <td>
//                             <TextInputForm
//                               placeholder={'discount_percentage'}
//                               name='discount_percentage'
//                               value={formData.discount_percentage}
//                               onChange={(e) => handleChange(e, 'discount_percentage')}
//                             />
//                           </td>
//                           <td>
//                             <TextInputForm
//                               placeholder={'discount_amt'}
//                               name='discount_amt'
//                               value={formData.discount_amt}
//                               onChange={(e) => handleChange(e, 'discount_amt')}
//                             />
//                           </td>

//                           <td className='w-10 text-left'>
//                             <DropDownUI
//                               optionlist={alltax}
//                               placeholder='Tax'
//                               name='tax_percentage'
//                               value={formData.tax_percentage}
//                               onChange={(updatedFormData) => {
//                                 setFormData({ ...formData, tax_percentage: updatedFormData.tax_percentage })
//                               }}
//                             />
//                           </td>
//                           <td>
//                             <TextInputForm
//                               placeholder={'tax_amt'}
//                               name='tax_amt'
//                               value={formData.tax_amt}
//                               onChange={(e) => handleChange(e, 'tax_amt')}

//                             />
//                           </td>
//                           <td>
//                             <TextInputForm
//                               placeholder={'amt'}
//                               name='total_amt'
//                               value={formData.total_amt}
//                               onChange={(e) => handleChange(e, 'total_amt')}
//                             />
//                           </td>
//                           <td className='' >
//                             <div className=''>
//                               <ClickButton label={<>Add More</>} />
//                             </div>
//                           </td>
//                           <td>
//                             <Delete label={<MdDeleteForever />}></Delete>
//                           </td>
//                         </tr>
//                       </tbody>
//                     </Table>
//                   </div>
//                 </Col>
//               </Row>
//             </Container>
//           </div>
//           <div className='my-3'>
//             <Container>
//               <Row>
//                 <Col lg='5'>
//                   {/* <PayMents /> */}
//                 </Col>
//                 <Col lg='7'>
//                   <div className='inside my-3'>
//                     <Table>
//                       <tr>
//                         <td>Total</td>
//                         <td></td>
//                         <td className='w-25'>
//                           <TextInputForm
//                             placeholder={'Total'}
//                             name='total'
//                             value={type === 'edit' ? formData.total : formData.total}
//                           />
//                         </td>
//                       </tr>
//                       {/* <tr>
//                         <td>Discount</td>
//                         <td className='w-10'>
//                           <TextInputForm
//                             placeholder={'discount_all'}
//                             name='discount_all'
//                             value={type === 'edit' ? formData.discount_all : formData.discount_all}
//                             onChange={(e) => handleChange(e, 'discount_all')}
//                             disabled={formData.product.some(row => row.discount_percentage)}
//                           />

//                         </td>
//                         <td className='w-25'>
//                           <TextInputForm
//                             placeholder={'TotalDiscount'}
//                             name='total_discountall'
//                             value={type === 'edit' ? formData.total_discountall : formData.total_discountall}
//                             onChange={(e) => handleChange(e, 'total_discountall')}
//                             disabled={formData.product.some(row => row.discount_amt)}
//                           />

//                         </td>
//                       </tr> */}

//                       <tr>
//                         {/* <td><input type="checkbox" className='mx-2' onChange={(e) => handleChange(e, 'round_off_checkbox')} /> Round off</td>
//                         <td className='w-15'>
//                           <TextInputForm
//                             placeholder={'round_off'}
//                             name='round_off'
//                             value={type === 'edit' ? formData.round_off : formData.round_off}
//                             onChange={(e) => handleChange(e, 'round_off')}
//                           />
//                         </td> */}
//                         {/* <td className='w-25'>
//                           <TextInputForm
//                             placeholder={'finaltotal'}
//                             name='final_total'
//                             value={type === 'edit' ? formData.final_total : formData.final_total}
//                             onChange={(e) => handleChange(e, 'final_total')}
//                           />
//                         </td> */}
//                       </tr>
//                       <tr>
//                         <td>Paid</td>
//                         <td></td>
//                         <td className='w-25'>
//                           <TextInputForm
//                             placeholder={'Paid'}
//                             name='paid'

//                             value={type === 'edit' ? formData.paid : formData.paid}

//                           />
//                         </td>
//                       </tr>
//                       <tr>
//                         <td>Balance</td>
//                         <td></td>
//                         <td className='w-25'>
//                           <TextInputForm
//                             placeholder={'balance'}
//                             name='balance_amount'
//                             value={type === 'edit' ? formData.balance_amount : formData.balance_amount}
//                             onChange={(e) => handleChange(e, 'balance_amount')}
//                           />
//                         </td>
//                       </tr>
//                     </Table>
//                   </div>
//                 </Col>
//               </Row>
//             </Container>
//           </div>
//         </div>
//         <div className='main stick-bottom'>
//           <div className='w-50 ms-auto'>
//             <Container>
//               <Row>
//                 <Col lg="12">
//                   <div className='text-center'>
//                     {type === 'edit' ? (
//                       <>
//                         <ToastContainer
//                           position="bottom-center"
//                           autoClose={3000}
//                           hideProgressBar={false}
//                           newestOnTop={false}
//                           closeOnClick
//                           rtl={false}
//                           pauseOnFocusLoss
//                           draggable
//                           pauseOnHover
//                           theme="light"
//                         />
//                         <span className='mx-2'>
//                           <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

//                         </span>

//                         <span className='mx-2'>
//                           <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/purchase/bill")}></ClickButton>
//                         </span>
//                       </>

//                     ) : (
//                       <>
//                         <ToastContainer
//                           position="bottom-center"
//                           autoClose={3000}
//                           hideProgressBar={false}
//                           newestOnTop={false}
//                           closeOnClick
//                           rtl={false}
//                           pauseOnFocusLoss
//                           draggable
//                           pauseOnHover
//                           theme="light"
//                         />
//                         <span className='mx-2'>
//                           <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>

//                         </span>

//                         <span className='mx-2'>
//                           <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/purchase/bill")}></ClickButton>
//                         </span>
//                       </>
//                     )}
//                   </div>
//                 </Col>
//               </Row>
//             </Container>
//           </div>
//         </div>
//       </div>
//     </>

//   )
// }

const PurchaseReturnEntry = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    });
  });
  return (
    <>
      {loading ? (
        <PropagateLoader
          className="text-center pt-5 "
          color={"#d43333"}
          loading={loading}
          size={18}
        />
      ) : (
        <div>
          <div className="main stick-top">
            <Container>
              <Row>
                <Col lg="12" className="py-3">
                  <PageHead
                    pagehead={<>Purchase Return</>}
                    onClick={() => navigate(-1)}
                  />
                </Col>
                <Col lg="12" className="py-3">
                  <Table>
                    <tr>
                      <td>
                        <div className="w-75">
                          <DropDown />
                        </div>
                      </td>
                      <td className="mx-auto float-end">
                        <div>
                          {" "}
                          Order No : <span>10</span>
                        </div>
                        <div>
                          <div className="text-left">Order Date </div>{" "}
                          <Calender />
                        </div>
                        <div>
                          <div>Due Date </div> <Calender />
                        </div>
                      </td>
                    </tr>
                  </Table>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="main my-3">
            <div>
              <Container>
                <Row>
                  <Col lg="12" className="py-3">
                    <div>
                      No Of Products : <span>15</span>
                    </div>
                  </Col>
                  <Col lg="12">
                    <div className="py-2 category-table bill">
                      <Table>
                        <thead className="">
                          <tr>
                            <th rowSpan={2}>#</th>
                            <th rowSpan={2} className="wrdbrk w-20">
                              Product Name
                            </th>
                            <th rowSpan={2} className="wrdbrk w-7">
                              Qty
                            </th>
                            <th rowSpan={2} className="wrdbrk w-10">
                              Unit
                            </th>
                            <th rowSpan={2} className="wrdbrk w-7">
                              Price / Unit
                            </th>
                            <th colSpan={2} className="wrdbrk w-15">
                              Discount
                            </th>
                            <th colSpan={2} className="wrdbrk w-15">
                              Tax
                            </th>
                            <th rowSpan={2} className="wrdbrk">
                              Amount
                            </th>
                          </tr>
                          <tr>
                            <th>%</th>
                            <th className="w-10">Amount</th>
                            <th>%</th>
                            <th className="w-10">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Dltbtn
                                lable={
                                  <>
                                    <MdOutlineDeleteOutline />
                                  </>
                                }
                              />
                            </td>
                            <td>
                              <DropDown />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                            <td className="wrdbrk">
                              <DropDown />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                            <td className="wrdbrk">
                              <DropDown />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Dltbtn
                                lable={
                                  <>
                                    <MdOutlineDeleteOutline />
                                  </>
                                }
                              />
                            </td>
                            <td>
                              <DropDown />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                            <td className="wrdbrk">
                              <DropDown />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                            <td className="wrdbrk">
                              <DropDown />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                            <td>
                              <TextInputForm />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="my-3">
              <Container>
                <Row>
                  <Col lg="5">{/* <PayMents /> */}</Col>
                  <Col lg="7">
                    <div className="inside my-3">
                      <Table>
                        <tr>
                          <td>Total</td>
                          <td></td>
                          <td className="w-25">
                            {" "}
                            <TextInputForm />
                          </td>
                        </tr>
                        <tr>
                          <td>Discount</td>
                          <td className="w-5">
                            {" "}
                            <TextInputForm />
                          </td>
                          <td className="w-25">
                            {" "}
                            <TextInputForm />
                          </td>
                        </tr>
                        <tr>
                          <td>Tax</td>
                          <td className="w-5">
                            {" "}
                            <TextInputForm />
                          </td>
                          <td className="w-25">
                            {" "}
                            <TextInputForm />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" className="mx-2" /> Round off
                          </td>
                          <td></td>
                          <td className="w-25">
                            {" "}
                            <TextInputForm />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" className="mx-2" /> Paid
                          </td>
                          <td></td>
                          <td className="w-25">
                            {" "}
                            <TextInputForm />
                          </td>
                        </tr>
                        <tr>
                          <td>Balance</td>
                          <td></td>
                          <td className="w-25">
                            {" "}
                            <TextInputForm />
                          </td>
                        </tr>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="main stick-bottom">
            <div className="w-50 ms-auto">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="text-end">
                      <span className="mx-2">
                        {" "}
                        <Buttons lable={<>Print</>} />
                      </span>
                      <span className="mx-2">
                        {" "}
                        <Buttons lable={<>Save & New</>} />
                      </span>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { PurchaseBillEntry, PurchaseReturnEntry };
