import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Offcanvas, Nav } from "react-bootstrap";
import { Buttons, ClickButton } from "../components/Buttons";
import { TableUI } from "../components/Tables";
import { PageHead, PageTitle } from "../components/PageTitle";
import { Calender, DropDownUI, TextInputForm } from "../components/Forms";
import { MdOutlineClose } from "react-icons/md";
import { useLocation, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import API_DOMAIN from "../config/config";
import "react-toastify/dist/ReactToastify.css";
const ProductHistory = ["", "Date", "Receipt No", "Name", "Total", "status"];
const ExpenseTablehead = ["Category", "Status"];
const ExpenseItem = ["Name", "Total", "Status"];
const Expenses = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};

  const [openFilter, setFilter] = useState(false);
  const [openItem, setopenItem] = useState(false);
  // const [Limit, SetLimit] = useState();
  const closeFilter = () => setFilter(false);
  const showFilter = () => setFilter(true);
  const openItems = () => setopenItem(true);
  const closeFilterItem = () => setopenItem(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setExpensesCate(null);
    setFormData({ category_name: "" });
    setShow(true);
  };
  const [item, setItem] = useState(false);
  const itemClose = () => setItem(false);
  const itemShow = () => {
    setExpensesItem(null);
    setFormDataItem({
      category_id: "",
      party_name: "",
      total: "",
    });
    setItem(true);
  };
  const clearFilter = () => {
    setFilterData({
      search_text: "",
      party_id: "",
      from_date: "",
      to_date: "",
    });
  };
  const [clickedData, setClickedData] = useState([]);
  console.log("clickedData", clickedData);
  const handleClick = (data) => {
    setClickedData(data);
  };
  const [clickedDataItem, setClickedDataItem] = useState([]);
  console.log("clickedDataItem", clickedDataItem);
  const handleClickItem = (data) => {
    setClickedDataItem(data);
  };

  const [crtExpensesCate, setExpensesCate] = useState(null);
  const handleEditClick = (rowdata) => {
    setExpensesCate(rowdata);
    setShow(true);
  };
  const [crtExpensesItem, setExpensesItem] = useState(null);
  const handleEditClickItem = (rowdata) => {
    setExpensesItem(rowdata);
    setItem(true);
  };
  const [formData, setFormData] = useState({
    category_name: "",
  });
  const [formDataItem, setFormDataItem] = useState({
    category_id: "",
    party_name: "",
    total: "",
  });
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtExpensesCate != null) {
      setExpensesCate({
        ...crtExpensesCate,
        [fieldName]: value,
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }
  };
  const handleChangeItem = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtExpensesItem != null) {
      setExpensesItem({
        ...crtExpensesItem,
        [fieldName]: value,
      });
    } else {
      setFormDataItem({
        ...formDataItem,
        [fieldName]: value,
      });
    }
  };
  const [filterData, setFilterData] = useState({
    search_text: "",
    party_id: "",
    category_id: "",
    from_date: "",
    to_date: "",
  });
  console.log("kanna", filterData);
  const handleChanges = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFilterData({
      ...filterData,
      [fieldName]: value,
    });
  };
  const setLabel = (value, field) => {
    setFilterData({
      ...filterData,
      [field]: moment(value).format("YYYY-MM-DD"), // Update the specified field in formData with the provided value
    });
  };
  const [expcategoryData, setExpcategoryData] = useState([]);
  const fetchExpensescategoryData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/expensecategory.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        // Set userData to the array of purchase parties
        setExpcategoryData(responseData.data);
        console.log(responseData);
        if (responseData.data.length > 0) {
          setClickedData(responseData.data[0]);
        }
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchExpensescategoryData(); // Call fetchData directly in useEffect
  }, []);
  const [expitemData, setexpitemData] = useState([]);
  console.log("exp", expitemData);
  const fetchExpensesItemData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/expenseitem.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        }
      );

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        // Set userData to the array of purchase parties
        setexpitemData(responseData.data);
        console.log(responseData);
        if (responseData.data.length > 0) {
          setClickedDataItem(responseData.data[0]);
        }
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchExpensesItemData(); // Call fetchData directly in useEffect
  }, [filterData]);
  const [loading, setLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleSubmit = async () => {
    try {
      const companyId = localStorage.getItem("companyId");

      const response = await fetch(
        `${API_DOMAIN}/expensecategory.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      console.log(formData);
      const responseData = await response.json();

      console.log(responseData);

      if (responseData.status === 200) {
        setFormData({
          categroy_name: "",
        });
        fetchExpensescategoryData();
        successAlert(responseData.msg);
        setTimeout(() => {
          handleClose(); // Close modal after 10 seconds
        }, 2000);
      } else if (responseData.status === 400) {
        toast.error("Missing required fields!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/expensecategory.php?id=${companyId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // Include the company ID in the request
            category_id: crtExpensesCate.category_id,
            category_name: crtExpensesCate.category_name,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update company");
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        fetchExpensescategoryData();
        toast.success("Expenses Category updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          handleClose();
        }, 2000);

        // Navigate to the user list page after a delay
      } else {
        console.error(
          responseData.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating product:", error.msg);
    }

    setLoading(false);
  };
  const handleSubmitItem = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/expenseitem.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDataItem),
        }
      );
      console.log(formDataItem);
      const responseData = await response.json();

      console.log(responseData);

      if (responseData.status === 200) {
        setFormDataItem({
          category_id: "",
          party_name: "",
          total: "",
        });
        fetchExpensesItemData();
        successAlert(responseData.msg);
        setTimeout(() => {
          itemClose(); // Close modal after 10 seconds
        }, 2000);
      } else if (responseData.status === 400) {
        toast.error("Missing required fields!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateSubmitItem = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/expenseitem.php?id=${companyId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // Include the company ID in the request
            expenses_item_id: crtExpensesItem.expenses_item_id,
            category_id: crtExpensesItem.category_id,
            party_name: crtExpensesItem.party_name,
            total: crtExpensesItem.total,
          }),
        }
      );

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        fetchExpensesItemData();
        toast.success("Expenses Item updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          itemClose();
        }, 2000);

        // Navigate to the user list page after a delay
      } else {
        console.error(
          responseData.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating product:", error.msg);
    }

    setLoading(false);
  };
  const [activeTab, setActiveTab] = useState("category");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "category":
        return (
          <div className="expense_category">
            <Container fluid>
              <Row className="justify-content-center">
                <Col md={8}>
                  <div className="main p-2 my-1">
                    <div className="fixed">
                      <div></div>
                      <div className="text-end py-2">
                        <Buttons lable={<> Add New</>} onClick={handleShow} />
                      </div>
                    </div>
                    <div className="py-2 c-table">
                      <TableUI
                        headers={ExpenseTablehead}
                        body={expcategoryData}
                        type="expensescategory"
                        handleEditClick={handleEditClick}
                        onDelete={fetchExpensescategoryData}
                        onClick={handleClick}
                      >
                        {" "}
                      </TableUI>
                    </div>
                  </div>
                </Col>
                {/* <Col lg='9'>
                <div className='my-1'>
                  <div className='main'>
                    <Row>
                      <Col lg='4'>
                        <PageTitle PageTitle={<>Transactions</>} />
                      </Col>
                      <Col lg="8">
                        <div className='d-flex justify-content-end'>
                          <div className='text-end mx-2'>
                            <Buttons lable={<>PDF</>} />
                          </div>
                          <div className='text-end mx-2'>
                            <Buttons lable={<>Filter</>} onClick={showFilter} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Offcanvas show={openFilter} onHide={closeFilter} placement='end'>
                      <Offcanvas.Body>
                        <Row>
                          <Col lg="6">
                            <PageTitle PageTitle={<>Expense</>} />
                          </Col>
                          <Col lg="6" className='align-self-center'>
                            <div className='text-end'>
                              <Buttons onClick={closeFilter} lable={<><MdOutlineClose /></>}></Buttons>
                            </div>
                          </Col>
                        </Row>
                        <div className='py-3'>
                          <TextInputForm labelname={<>Expense No.</>} />
                        </div>
                        <div className='py-3'>
                          <DropDownUI labelname={"Select Party"} />
                        </div>
                        <div className='py-3'>
                          <DropDownUI labelname={" Select Category"} />
                        </div>
                        <Row>
                          <Col lg="6" className='align-self-center'>
                            <div>
                              <Calender labelname={<>From</>} />
                            </div>
                          </Col>
                          <Col lg="6" className='align-self-center'>
                            <div>
                              <Calender labelname={<>To</>} />
                            </div>
                          </Col>
                          <Col lg="6" className='align-self-center'>
                            <div className='my-5 text-center'>
                              <Buttons lable={"Apply"}></Buttons>
                            </div>
                          </Col>
                          <Col lg="6" className='align-self-center'>
                            <div className='my-5 text-center'>
                              <Buttons lable={"Clear"}></Buttons>
                            </div>
                          </Col>
                        </Row>
                      </Offcanvas.Body>
                    </Offcanvas>
                    <div className='py-2 c-table'>
                      <TableUI headers={ProductHistory} body={[]} type='expensescategorytransactions' pageview={"yes"}></TableUI>
                    </div>
                  </div>
                </div>
              </Col> */}
              </Row>
            </Container>
            <>
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                  <PageHead pagehead={<>Expense </>} onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col lg="12" className="py-3">
                        <TextInputForm
                          labelname={"CategoryName"}
                          placeholder={"CategoryName"}
                          name="category_name"
                          value={
                            crtExpensesCate != null
                              ? crtExpensesCate.category_name
                              : formData.category_name
                          }
                          onChange={(e) => handleChange(e, "category_name")}
                        ></TextInputForm>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Col
                    lg="12"
                    md="12"
                    xs="12"
                    className="py-5 align-self-center"
                  >
                    <div className="text-center">
                      {crtExpensesCate != null ? (
                        <>
                          <ToastContainer
                            position="top-center"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
                          <span className="mx-2">
                            <ClickButton
                              label={<>Update</>}
                              onClick={handleUpdateSubmit}
                            ></ClickButton>
                          </span>

                          <span className="mx-2">
                            <ClickButton
                              label={<>Cancel</>}
                              onClick={handleClose}
                            ></ClickButton>
                          </span>
                        </>
                      ) : (
                        <>
                          <ToastContainer
                            position="top-center"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
                          <span className="mx-2">
                            <ClickButton
                              label={<>Submit</>}
                              onClick={handleSubmit}
                            ></ClickButton>
                          </span>

                          <span className="mx-2">
                            <ClickButton
                              label={<>Cancel</>}
                              onClick={handleClose}
                            ></ClickButton>
                          </span>
                        </>
                      )}
                    </div>
                  </Col>
                </Modal.Footer>
              </Modal>
            </>
          </div>
        );
      case "items":
        return (
          <div>
            <Container fluid>
              <Row className="justify-content-center">
                <Col md={8}>
                  <div className="main p-2 my-1">
                    <div className="fixed">
                      <div></div>
                      <div className="text-end py-2">
                        <Buttons lable={<> Add New</>} onClick={itemShow} />
                      </div>
                    </div>
                    <div className="py-2 c-table">
                      <TableUI
                        headers={ExpenseItem}
                        body={expitemData}
                        type="expensesitems"
                        handleEditClick={handleEditClickItem}
                        onClick={handleClickItem}
                        onDelete={fetchExpensesItemData}
                      >
                        {" "}
                      </TableUI>
                    </div>
                  </div>
                </Col>
                {/* <Col lg='9'>
                <div className='my-3'>
                  <div className='main'>
                    <Row>
                      <Col lg='4'>
                        <PageTitle PageTitle={<>Transactions</>} />
                      </Col>
                      <Col lg="8">
                        <div className='d-flex justify-content-end'>
                          <div className='text-end mx-2'>
                            <Buttons lable={<>Filter</>} onClick={openItems} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className='py-2 c-table'>
                      <TableUI headers={ProductHistory} body={[]} type='expensesitemtransactions' pageview={"yes"}></TableUI>
                    </div>
                  </div>
                </div>
              </Col> */}
                <Offcanvas
                  show={openItem}
                  onHide={closeFilterItem}
                  placement="end"
                >
                  <Offcanvas.Body>
                    <Row>
                      <Col lg="6">
                        <PageTitle PageTitle={<>Expense</>} />
                      </Col>
                      <Col lg="6" className="align-self-center">
                        <div className="text-end">
                          <Buttons
                            onClick={closeFilterItem}
                            lable={
                              <>
                                <MdOutlineClose />
                              </>
                            }
                          ></Buttons>
                        </div>
                      </Col>
                    </Row>
                    <div className="py-3">
                      <TextInputForm
                        labelname={"Expense No."}
                        placeholder={"Expense No."}
                        name="search_text"
                        value={filterData.search_text}
                        onChange={(e) => handleChanges(e, "search_text")}
                      ></TextInputForm>
                    </div>
                    <div className="py-3">
                      <DropDownUI
                        optionlist={expitemData.map((user) => ({
                          value: user.party_id,
                          label: user.party_name,
                        }))}
                        placeholder="PartyName"
                        labelname="PartyName"
                        name="party_id"
                        value={filterData.party_id}
                        onChange={(updatedFormData) =>
                          setFilterData({
                            ...filterData,
                            party_id: updatedFormData.party_id,
                          })
                        }
                      />
                    </div>
                    <div className="py-3">
                      <DropDownUI
                        optionlist={expcategoryData.map((user) => ({
                          value: user.catrgory_id,
                          label: user.category_name,
                        }))}
                        placeholder="CategoryName"
                        labelname="CategoryName"
                        name="category_id"
                        value={filterData.category_id}
                        onChange={(updatedFormData) =>
                          setFilterData({
                            ...filterData,
                            category_id: updatedFormData.category_id,
                          })
                        }
                      />
                    </div>
                    <Row>
                      <Col lg="6" className="align-self-center">
                        <div>
                          <Calender labelname={<>From</>} />
                        </div>
                      </Col>
                      <Col lg="6" className="align-self-center">
                        <div>
                          <Calender labelname={<>To</>} />
                        </div>
                      </Col>
                      <Col lg="12" className="align-self-center">
                        <div className="my-5 text-center">
                          <Buttons
                            lable={"Clear"}
                            onClick={clearFilter}
                          ></Buttons>
                        </div>
                      </Col>
                    </Row>
                  </Offcanvas.Body>
                </Offcanvas>
              </Row>
            </Container>
            <>
              <Modal show={item} onHide={itemClose} centered size="lg">
                <Modal.Header>
                  <PageHead pagehead={<>Expense </>} onClick={itemClose} />
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col lg="4" className="py-3">
                        <DropDownUI
                          optionlist={expcategoryData.map((user) => ({
                            value: user.category_id,
                            label: user.category_name,
                          }))}
                          placeholder="Category"
                          labelname="Category"
                          name="category_id"
                          value={
                            crtExpensesItem != null
                              ? crtExpensesItem.category_id
                              : formDataItem.category_id
                          }
                          onChange={(updatedFormData) => {
                            if (crtExpensesItem != null) {
                              setExpensesItem({
                                ...crtExpensesItem,
                                category_id: updatedFormData.category_id,
                              });
                            } else {
                              setFormDataItem({
                                ...formDataItem,
                                category_id: updatedFormData.category_id,
                              });
                            }
                          }}
                        />
                      </Col>
                      <Col lg="4" className="py-3">
                        <TextInputForm
                          labelname={"PartyName"}
                          placeholder={"PartyName"}
                          name="party_name"
                          value={
                            crtExpensesItem != null
                              ? crtExpensesItem.party_name
                              : formDataItem.party_name
                          }
                          onChange={(e) => handleChangeItem(e, "party_name")}
                        ></TextInputForm>
                      </Col>
                      <Col lg="4" className="py-3">
                        <TextInputForm
                          labelname={"total"}
                          placeholder={"total"}
                          name="total"
                          value={
                            crtExpensesItem != null
                              ? crtExpensesItem.total
                              : formDataItem.total
                          }
                          onChange={(e) => handleChangeItem(e, "total")}
                        ></TextInputForm>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <div className="text-center">
                    {crtExpensesItem != null ? (
                      <>
                        <ToastContainer
                          position="top-center"
                          autoClose={3000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="light"
                        />
                        <span className="mx-2">
                          <ClickButton
                            label={<>Update</>}
                            onClick={handleUpdateSubmitItem}
                          ></ClickButton>
                        </span>

                        <span className="mx-2">
                          <ClickButton
                            label={<>Cancel</>}
                            onClick={itemClose}
                          ></ClickButton>
                        </span>
                      </>
                    ) : (
                      <>
                        <ToastContainer
                          position="top-center"
                          autoClose={3000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="light"
                        />
                        <span className="mx-2">
                          <ClickButton
                            label={<>Submit</>}
                            onClick={handleSubmitItem}
                          ></ClickButton>
                        </span>

                        <span className="mx-2">
                          <ClickButton
                            label={<>Cancel</>}
                            onClick={itemClose}
                          ></ClickButton>
                        </span>
                      </>
                    )}
                  </div>
                </Modal.Footer>
              </Modal>
            </>
          </div>
        );

      default:
        return null;
    }
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem("session");
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking session:", error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return (
    <div>
      <div>
        <Container fluid>
          <div className="main my-2 expense p-0 stick-top">
            <Row>
              <Col lg="12">
                <Nav className=" py-2 justify-content-center">
                  <li>
                    <Nav.Link
                      onClick={() => handleTabClick("category")}
                      className={
                        activeTab === "category" ? "navlink active" : "navlink"
                      }
                    >
                      category
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link
                      onClick={() => handleTabClick("items")}
                      className={
                        activeTab === "items" ? "navlink active" : "navlink"
                      }
                    >
                      Items
                    </Nav.Link>
                  </li>
                </Nav>
              </Col>
            </Row>
          </div>
        </Container>

        <div>{renderContent()}</div>
      </div>
    </div>
  );
};

export default Expenses;
