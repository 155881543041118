import React from "react";
import Pdf from "./Pdf";
import { PDFViewer } from "@react-pdf/renderer";

import EstimationPdf from "./EstimationPdf";
const PdfPreview = () => (
  <PDFViewer height={"1000"} width={"100%"}>
    <Pdf />
  </PDFViewer>
);
const PdfEstimatePreview = () => (
  <PDFViewer height={"1000"} width={"100%"}>
    <EstimationPdf />
  </PDFViewer>
);

export { PdfPreview, PdfEstimatePreview };
