import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Tabs, Modal } from "react-bootstrap";
import { Calender, TextInputForm, DropDownUI } from "../components/Forms";
import { Buttons, ClickButton } from "../components/Buttons";
import { PageHead, PageTitle } from "../components/PageTitle";
import { useNavigate, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import API_DOMAIN from "../config/config";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment/moment";
const ItemCreation = () => {
  const [conversionList, SetConversionList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    location.state = [];
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          product_name: "",
          category_id: "",
          hsn_no: "",
          item_code: "",
          unit_id: "",
          subunit_id: "",
          unit_rate: "",
          opening_stock: "",
          opening_date: "",
          min_stock: "",
        };
  const [formData, setFormData] = useState(initialState);
  console.log(formData);
  const [productUnit, setProductUnit] = useState({
    unit_id: "",
    subunit_id: "",
    conversion_id: "",
  });
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: moment(value).format("YYYY-MM-DD"), // Update the specified field in formData with the provided value
    });
  };
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const fetchData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/unit.php?id=${companyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setUnitData(responseData.data);
        console.log(setUnitData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, []);
  const fetchCategoryData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/category.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setCategoryData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchCategoryData(); // Call fetchData directly in useEffect
  }, []);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleSubmit = async () => {
    try {
      console.log(formData);
      if (formData.product_name === "") {
        if (formData.product_name === "") {
          errorAlert("Product Name Is Must");
        }
      } else {
        const companyId = localStorage.getItem("companyId");
        const response = await fetch(
          `${API_DOMAIN}/products.php?id=${companyId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        console.log(JSON.stringify("formData", formData));
        const responseData = await response.json();

        console.log(responseData);

        if (responseData.status === 200) {
          setFormData({
            product_name: "",
            category_id: "",
            hsn_no: "",
            item_code: "",
            unit_id: "",
            subunit_id: "",
            unit_rate: "",
            opening_stock: "",
            opening_date: "",
            min_stock: "",
          });
          successAlert(responseData.msg);
          setTimeout(() => {
            navigate("/console/item/product");
          }, 2000);
        } else if (responseData.status === 400) {
          toast.error(responseData.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/products.php?id=${companyId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // Include the company ID in the request
            product_id: formData.product_id,
            product_name: formData.product_name,
            category_id: formData.category_id,
            hsn_no: formData.hsn_no,
            item_code: formData.item_code,
            unit_id: formData.unit_id,
            subunit_id: formData.subunit_id,
            unit_rate: formData.unit_rate,
            opening_stock: formData.opening_stock,
            min_stock: formData.min_stock,
            opening_date: formData.opening_date,
          }),
        }
      );
      console.log(
        JSON.stringify({
          // Include the company ID in the request
          product_id: formData.product_id,
          product_name: formData.product_name,
          category_id: formData.category_id,
          hsn_no: formData.hsn_no,
          item_code: formData.item_code,
          unit_id: formData.unit_id,
          subunit_id: formData.subunit_id,
          unit_rate: formData.unit_rate,
          opening_stock: formData.opening_stock,
          min_stock: formData.min_stock,
          opening_date: formData.opening_date,
        })
      );
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        toast.success("Product updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/item/product");
        }, 2000);

        // Navigate to the user list page after a delay
      } else {
        console.error(
          responseData.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating product:", error.msg);
    }
    setLoading(false);
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem("session");
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking session:", error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return (
    <div className="main p-0 my-3">
      <div>
        <div className="page-head p-2 fixed">
          <PageHead pagehead={<>Add Item</>} onClick={() => navigate(-1)} />
        </div>
        <div className="inside-content p-3">
          <div>
            <Container fluid>
              <Row>
                <Col lg="4" className="py-3 align-self-center">
                  <TextInputForm
                    labelname={"Product Name"}
                    placeholder={"Product Name"}
                    name="product_name"
                    value={
                      type === "edit"
                        ? formData.product_name
                        : formData.product_name
                    }
                    onChange={(e) => handleChange(e, "product_name")}
                  />
                </Col>
                <Col lg="4" className="py-3 align-self-center">
                  <TextInputForm
                    labelname={"HSN No"}
                    placeholder={"HSN No"}
                    name="hsn_no"
                    value={type === "edit" ? formData.hsn_no : formData.hsn_no}
                    onChange={(e) => handleChange(e, "hsn_no")}
                  />
                </Col>
                {/* <Col lg="4" className="py-3 align-self-center">
                  <TextInputForm
                    labelname={"Item Code"}
                    placeholder={"Item Code"}
                    name="item_code"
                    value={
                      type === "edit" ? formData.item_code : formData.item_code
                    }
                    onChange={(e) => handleChange(e, "item_code")}
                  />
                </Col> */}
                <Col lg="4" className="align-self-center py-3">
                  <DropDownUI
                    optionlist={categoryData.map((user) => ({
                      value: user.category_id,
                      label: user.category_name,
                    }))}
                    placeholder="Category"
                    labelname="Category"
                    name="category_id"
                    value={
                      type === "edit"
                        ? formData.category_id
                        : formData.category_id
                    }
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        category_id: updatedFormData.category_id,
                      })
                    }
                  />
                </Col>
                <Col lg="4" className="py-3">
                  <DropDownUI
                    optionlist={unitData.map((user) => ({
                      value: user.unit_id,
                      label: user.unit_name,
                    }))}
                    placeholder="Select"
                    labelname="Unit"
                    name="unit_id"
                    value={
                      type === "edit" ? formData.unit_id : formData.unit_id
                    }
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        unit_id: updatedFormData.unit_id,
                      })
                    }

                    // filterConversionList()
                  />
                </Col>
                {/* <Col lg="4" className="py-3">
                  <DropDownUI
                    optionlist={unitData.map((user) => ({
                      value: user.unit_id,
                      label: user.unit_name,
                    }))}
                    placeholder="Select"
                    labelname="SubUnit"
                    name="subunit_id"
                    value={
                      type === "edit"
                        ? formData.subunit_id
                        : formData.subunit_id
                    }
                    onChange={(updatedFormData) => {
                      setFormData({
                        ...formData,
                        subunit_id: updatedFormData.subunit_id,
                      });

                      // filterConversionList()
                    }}
                  />
                </Col> */}
                <Col lg="4" className="py-3 align-self-center">
                  <TextInputForm
                    labelname={"Sale Rate"}
                    placeholder={"Sale Rate"}
                    name="unit_rate"
                    value={
                      type === "edit" ? formData.unit_rate : formData.unit_rate
                    }
                    onChange={(e) => handleChange(e, "unit_rate")}
                  />
                </Col>
               
              </Row>
            </Container>
          </div>
          <div className="py-3">
            <Tabs id="fill-tab-example" className="mb-3" fill>
              {/* <Tab eventKey="Pricing" title="Pricing">
                <Container>
                  <Row>
                    <Col lg='6'>
                      <Row>
                        <Col lg='12' className='py-3'>
                          <PageTitle PageTitle={<>tra Price</>}></PageTitle>
                        </Col>
                        <Col lg='6' className='py-3 align-self-center'>
                          <TextInputForm labelname={"Sale Price"} />
                        </Col>
                        <Col lg='6' className='align-self-center py-3'>
                          <DropDown labelname='Tax' />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg='6'>
                      <Row>
                        <Col lg='12' className='py-3'>
                          <PageTitle PageTitle={<>Purchase Price</>}></PageTitle>
                        </Col>
                        <Col lg='6' className='py-3 align-self-center'>
                          <TextInputForm labelname={"Purchase Price"} />
                        </Col>
                        <Col lg='6' className='align-self-center py-3'>
                          <DropDown labelname='Tax' />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Tab> */}
              <Tab eventKey="Stock" title="Stock">
                <Container fluid>
                  <Row>
                    <Col lg="12">
                      <PageTitle PageTitle={<>Stocks</>}></PageTitle>
                    </Col>
                    <Col lg="3" className="py-3">
                      <TextInputForm
                        labelname={"Opening Stock"}
                        placeholder={"Opening Stock"}
                        name="opening_stock"
                        value={
                          type === "edit"
                            ? formData.opening_stock
                            : formData.opening_stock
                        }
                        onChange={(e) => handleChange(e, "opening_stock")}
                      />
                    </Col>
                    <Col lg="3" className="py-3">
                      <Calender
                        setLabel={(date) => setLabel(date, "opening_date")}
                        selectedDate={
                          type === "edit" ? formData.opening_date : ""
                        }
                        calenderlabel="OpeaningDate"
                      />
                    </Col>
                    <Col lg="3" className="py-3">
                      <TextInputForm
                        labelname={"Min Stock"}
                        name="min_stock"
                        value={
                          type === "edit"
                            ? formData.min_stock
                            : formData.min_stock
                        }
                        onChange={(e) => handleChange(e, "min_stock")}
                      />
                    </Col>
                    <div className="text-center">
                      {type === "edit" ? (
                        <>
                          <ToastContainer
                            position="bottom-center"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
                          <span className="mx-2">
                            <ClickButton
                              label={<>Update</>}
                              onClick={handleUpdateSubmit}
                            ></ClickButton>
                          </span>

                          <span className="mx-2">
                            <ClickButton
                              label={<>Cancel</>}
                              onClick={() => navigate("/console/item/product")}
                            ></ClickButton>
                          </span>
                        </>
                      ) : (
                        <>
                          <ToastContainer
                            position="bottom-center"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
                          <span className="mx-2">
                            <ClickButton
                              label={<>Submit</>}
                              onClick={handleSubmit}
                            ></ClickButton>
                          </span>

                          <span className="mx-2">
                            <ClickButton
                              label={<>Cancel</>}
                              onClick={() => navigate("/console/item/product")}
                            ></ClickButton>
                          </span>
                        </>
                      )}
                    </div>
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </div>
        </div>
        <>
          <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header>
              <PageHead pagehead={<> Add Unit</>} onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col lg="6" className="py-3">
                    <DropDownUI
                      optionlist={unitData.map((user) => ({
                        value: user.unit_id,
                        label: user.unit_name,
                      }))}
                      placeholder="Select"
                      labelname="Select"
                      name="unit_id"
                      value={
                        type === "edit" ? formData.unit_id : productUnit.unit_id
                      }
                      onChange={(updatedFormData) => {
                        setProductUnit({
                          ...productUnit,
                          unit_id: updatedFormData.unit_id,
                        });

                        // filterConversionList()
                      }}
                    />
                  </Col>
                  <Col lg="6" className="py-3">
                    <DropDownUI
                      optionlist={unitData.map((user) => ({
                        value: user.unit_id,
                        label: user.unit_name,
                      }))}
                      placeholder="Select"
                      labelname="Select"
                      name="subunit_id"
                      value={
                        type === "edit"
                          ? formData.unit_id
                          : productUnit.subunit_id
                      }
                      onChange={(updatedFormData) => {
                        setProductUnit({
                          ...productUnit,
                          subunit_id: updatedFormData.subunit_id,
                        });

                        // filterConversionList()
                      }}
                    />
                  </Col>
                  <Col lg="12" className="py-3">
                    <div>Add Conversion</div>
                  </Col>

                  {conversionList.map((item, index) => (
                    <Col lg="3" className="py-3">
                      <input
                        type="radio"
                        id={item.conversion_id}
                        name="unit_conversion_id"
                        value={item.conversion_id}
                      />
                      <label for={item.conversion_id}>{item.lable}</label>
                    </Col>
                  ))}

                  {/* <Col lg="3" className='py-3'>
                    <input type="radio" id="html" name="fav_language" value="HTML" />
                    <label for="html">1 Box = 120piece</label>
                  </Col>
                  <Col lg="3" className='py-3'>
                    <input type="radio" id="html" name="fav_language" value="HTML" />
                    <label for="html">1 Box = 60piece</label>
                  </Col>
                  <Col lg="3" className='py-3'>
                    <input type="radio" id="html" name="fav_language" value="HTML" />
                    <label for="html">1 Box = 20Box</label>
                  </Col>
                  <Col lg="3" className='py-3'>
                    <input type="radio" id="html" name="fav_language" value="HTML" />
                    <label for="html">1 Box = 10piece</label>
                  </Col> */}
                </Row>
                <div className="text-center py-4">
                  {/* { != null ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                      <span className='mx-2'>
                        <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                      </span>

                      <span className='mx-2'>
                        <ClickButton label={<>Cancel</>} onClick={() => handleClose()}></ClickButton>
                      </span>
                    </>

                  ) : (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                      <span className='mx-2'>
                        <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>
                      </span>

                      <span className='mx-2'>
                        <ClickButton label={<>Cancel</>} onClick={() => handleClose()}></ClickButton>
                      </span>
                    </>
                  )} */}
                </div>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <Buttons lable={<>Submit</>} />
              </div>
              <div>
                <Buttons lable={<>Submit</>} />
              </div>
            </Modal.Footer>
          </Modal>
        </>
        {/* <div className='page-bottom p-2 fixed text-center'>
          <span className='mx-2'>
            <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>
          </span>
          <span className='mx-2'>
            <ClickButton label={<>Cancel</>} onClick={() => { location.state = []; }}></ClickButton>
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default ItemCreation;
