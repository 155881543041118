import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Button, Navbar, Spinner } from "react-bootstrap";
import { DropDownUI } from "../components/Forms";
import { MdLogout } from "react-icons/md";
import "./Header.css";
import API_DOMAIN from "../config/config";

const Header = () => {
  const [isLoading, setIsLoading] = useState(false);
  const initialCompanyId = localStorage.getItem("companyId") || "";

  const [formData, setFormData] = useState({
    company_id: initialCompanyId,
  });
  const [userData, setUserData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/company.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(responseData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, []);
  const handleToggle = () => {
    document.body.classList.toggle("toggle-sidebar");
  };
  const handleLogout = () => {
    localStorage.removeItem("session");
    window.location.replace("/login"); // Redirect to login page after logout
  };
  const handleCompanyChange = (updatedFormData) => {
    setFormData({ ...formData, company_id: updatedFormData.company_id });
    localStorage.setItem("companyId", updatedFormData.company_id); // Store selected company in localStorage
    window.location.reload();
  };
  return (
    <div>
      <Navbar className="navfix navbg header fixed-top">
        <Container fluid>
          <div className="align-self-center">
            <Button
              className="menu-toggle"
              id="menu-toggle"
              onClick={handleToggle}
            >
              <span className="navbar-toggler-icon"></span>
            </Button>
          </div>
          <div className="user-logo mx-5">
            <img
              src={require("../assests/images/logo1.png")}
              className="img-fluid logo"
              alt=""
            />
          </div>
          <div className="ms-auto d-flex align-items-center">
            <div className="mx-5 switch-company">
              <DropDownUI
                optionlist={userData.map((user) => ({
                  value: user.company_id,
                  label: user.company_name,
                }))}
                placeholder="Select"
                name="company_id"
                value={formData.company_id}
                onChange={handleCompanyChange}
              />
            </div>

            <div className="ms-auto user-id">
              <Button>
                <MdLogout onClick={handleLogout} />
              </Button>
            </div>
          </div>
        </Container>
      </Navbar>
      {isLoading && (
        <div className="loading-overlay">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

export { Header };
