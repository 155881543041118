import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Offcanvas,
  Modal,
  ModalBody,
} from "react-bootstrap";
import { Buttons } from "../components/Buttons";
import { TableUI } from "../components/Tables";
import { PageTitle } from "../components/PageTitle";
import { useNavigate, Navigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import PropagateLoader from "react-spinners/PropagateLoader";
import { PageHead } from "../components/PageTitle";
import API_DOMAIN from "../config/config";
import { Calender, DropDownUI, TextInputForm } from "../components/Forms";
import moment from "moment/moment";
const ProductHistory = [
  "S No",
  "Date",
  "EstimateNumber",
  "PartyName",
  "Total",
  "Action",
];
const ProductHisData = [
  {
    values: [
      "1",
      "27/02/2024",
      "Siva",
      "12000",
      <Buttons lable={<>Convert To Sales</>} />,
    ],
  },
  {
    values: [
      "2",
      "28/02/2024",
      "shankar",
      "17895",
      <Buttons lable={<>Convert To Sales</>} />,
    ],
  },
  {
    values: [
      "3",
      "29/02/2024",
      "Ranjith",
      "12563",
      <Buttons lable={<>Convert To Sales</>} />,
    ],
  },
];
const Estimation = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [openFilter, setFilter] = useState(false);
  const closeFilter = () => setFilter(false);
  const showFilter = () => setFilter(true);
  const navigate = useNavigate();
  const [printrow, setPrintRow] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    });
  });
  const PrintModal = (rowData) => {
    setShow(true);
  };
  const [filterData, setFilterData] = useState({
    search_text: "",
    party_id: "",
    from_date: "",
    to_date: "",
  });
  const clearFilter = () => {
    setFilterData({
      search_text: "",
      party_id: "",
      from_date: "",
      to_date: "",
    });
  };
  console.log("kanna", filterData);
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFilterData({
      ...filterData,
      [fieldName]: value,
    });
  };
  const setLabel = (value, field) => {
    setFilterData({
      ...filterData,
      [field]: moment(value).format("YYYY-MM-DD"), // Update the specified field in formData with the provided value
    });
  };
  const [partySalesData, setPartySalesData] = useState([]);
  const fetchPartySalesData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/sales_party.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setPartySalesData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchPartySalesData(); // Call fetchData directly in useEffect
  }, []);
  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const fetchData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/salesestimation.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: filterData.search_text,
            party_id: filterData.party_id,
            from_date: filterData.from_date,
            to_date: filterData.to_date,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(setUserData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, [filterData]);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem("session");
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking session:", error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return (
    <div>
      <Container fluid>
        <div className="main p-3 my-3">
          <div>
            <Navbar>
              <Navbar.Brand>
                <PageTitle PageTitle={"Estimation"} />
              </Navbar.Brand>
              <Nav className="ms-auto">
                {/* <span className='mx-2 py-2'> <Buttons lable={<>PDF</>} /></span> */}
                <span className="mx-2 py-2">
                  <Buttons lable={<>Filter</>} onClick={showFilter} />
                </span>
              </Nav>
            </Navbar>
          </div>
          <Offcanvas show={openFilter} onHide={closeFilter} placement="end">
            <Offcanvas.Body>
              <Row>
                <Col lg="6">
                  <PageTitle PageTitle={<>Purchase Bill</>} />
                </Col>
                <Col lg="6" className="align-self-center">
                  <div className="text-end">
                    <Buttons
                      onClick={closeFilter}
                      lable={
                        <>
                          <MdOutlineClose />
                        </>
                      }
                    ></Buttons>
                  </div>
                </Col>
              </Row>
              <div>
                <div className="py-3">
                  <TextInputForm
                    labelname={"Search Receipt"}
                    placeholder={"Search Receipt"}
                    name="search_text"
                    value={filterData.search_text}
                    onChange={(e) => handleChange(e, "search_text")}
                  ></TextInputForm>
                </div>
                <div className="py-3">
                  <DropDownUI
                    optionlist={partySalesData.map((user) => ({
                      value: user.party_id,
                      label: user.party_name,
                    }))}
                    placeholder="PartyName"
                    labelname="PartyName"
                    name="party_id"
                    value={filterData.party_id}
                    onChange={(updatedFormData) =>
                      setFilterData({
                        ...filterData,
                        party_id: updatedFormData.party_id,
                      })
                    }
                  />
                </div>
                <Row>
                  <Col lg="6" className="align-self-center">
                    <div>
                      <Calender
                        setLabel={(date) => setLabel(date, "from_date")}
                        selectedDate={filterData.from_date}
                        calenderlabel="From Date"
                      />
                    </div>
                  </Col>
                  <Col lg="6" className="align-self-center">
                    <div>
                      <Calender
                        setLabel={(date) => setLabel(date, "to_date")}
                        selectedDate={filterData.to_date}
                        calenderlabel="To Date"
                      />
                    </div>
                  </Col>
                  <Col lg="12" className="align-self-center">
                    <div className="my-5 text-center">
                      <Buttons lable={"Clear"} onClick={clearFilter}></Buttons>
                    </div>
                  </Col>
                </Row>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div className="py-1 main">
          <Navbar>
            <Navbar.Brand>
              <PageTitle PageTitle={"Transactions"} />
            </Navbar.Brand>
            <Nav className="ms-auto">
              <span className="mx-2 py-2">
                {" "}
                <Buttons
                  lable={<>Add New</>}
                  onClick={() => navigate("/console/sales/estimation/create")}
                />
              </span>
            </Nav>
          </Navbar>
        </div>
        <div className="py-2 c-table table-scroll main">
          <TableUI
            headers={ProductHistory}
            body={userData}
            type="salesestimate"
            handleEditClick={PrintModal}
            pageview={"yes"}
            onDelete={fetchData}
          ></TableUI>
        </div>
      </Container>
      <>
        <Modal size="xs" show={show} onHide={handleClose} centered>
          <Modal.Header>
            <PageHead pagehead={<>Print Type</>} onClick={handleClose} />
          </Modal.Header>
          <ModalBody>
            <Container>
              <Row>
                <Col md={4}>
                  <input
                    type="radio"
                    id="original"
                    name="fav_language"
                    value="Original"
                  />
                  <label htmlFor="original">Original</label>
                </Col>
                <Col md={4}>
                  <input
                    type="radio"
                    id="duplicate"
                    name="fav_language"
                    value="Duplicate"
                  />
                  <label htmlFor="duplicate">Duplicate</label>
                </Col>
                <Col md={4}>
                  <input
                    type="radio"
                    id="triplicate"
                    name="fav_language"
                    value="Triplicate"
                  />
                  <label htmlFor="triplicate">Triplicate</label>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </>
    </div>
  );
};

export default Estimation;
