import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Col, Row, Offcanvas } from "react-bootstrap";
import { Buttons } from "../components/Buttons";
import { TableUI } from "../components/Tables";
import { PageTitle } from "../components/PageTitle";
import { useNavigate } from "react-router-dom";
import API_DOMAIN from "../config/config";
import { MdOutlineClose } from "react-icons/md";
import { Calender, DropDownUI, TextInputForm } from "../components/Forms";
const ProductHistory = [
  "S No",
  "Date",
  "Name",
  "Total",
  "Received",
  "Balance",
  "Action",
];
const PurchaseReturn = () => {
  const [openFilter, setFilter] = useState(false);
  const closeFilter = () => setFilter(false);
  const showFilter = () => setFilter(true);
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //     setLoading(true)
  //     setTimeout(() => {
  //         setLoading(false)
  //     })
  // })
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/purchase_return.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        console.log(responseData);
        setLoading(false);
        if (responseData.status === 200) {
          setUserData(responseData.data);
          console.log(setUserData);
        } else {
          throw new Error(responseData.msg);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData(); // Call fetchData directly in useEffect
  }, []);
  return (
    <div>
      <div className="main p-3">
        <Container>
          <div>
            <Navbar>
              <Navbar.Brand>
                <PageTitle PageTitle={"Purchase Return"} />
              </Navbar.Brand>
              <Nav className="ms-auto">
                <span className="mx-2 py-2">
                  {" "}
                  <Buttons lable={<>PDF</>} />
                </span>
                <span className="mx-2 py-2">
                  <Buttons lable={<>Filter</>} onClick={showFilter} />
                </span>
              </Nav>
            </Navbar>
          </div>
          <Offcanvas show={openFilter} onHide={closeFilter} placement="end">
            <Offcanvas.Body>
              <Row>
                <Col lg="6">
                  <PageTitle PageTitle={<>Return Bill</>} />
                </Col>
                <Col lg="6" className="align-self-center">
                  <div className="text-end">
                    <Buttons
                      onClick={closeFilter}
                      lable={
                        <>
                          <MdOutlineClose />
                        </>
                      }
                    ></Buttons>
                  </div>
                </Col>
              </Row>
              <div>
                <div className="py-3">
                  <TextInputForm labelname={<>Search Receipt</>} />
                </div>
                <div className="py-3">
                  <DropDownUI labelname={"Select Purchase Party"} />
                </div>
                <Row>
                  <Col lg="6" className="align-self-center">
                    <div>
                      <Calender labelname={<>From</>} />
                    </div>
                  </Col>
                  <Col lg="6" className="align-self-center">
                    <div>
                      <Calender labelname={<>To</>} />
                    </div>
                  </Col>
                  <Col lg="6" className="align-self-center">
                    <div className="my-5 text-center">
                      <Buttons lable={"Apply"}></Buttons>
                    </div>
                  </Col>
                  <Col lg="6" className="align-self-center">
                    <div className="my-5 text-center">
                      <Buttons lable={"Clear"}></Buttons>
                    </div>
                  </Col>
                </Row>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <div className="py-1">
            <Navbar>
              <Container>
                <Navbar.Brand>
                  <PageTitle PageTitle={"Transactions"} />
                </Navbar.Brand>
                <Nav className="ms-auto">
                  <span className="mx-2 py-2">
                    {" "}
                    <Buttons
                      lable={<>Add New</>}
                      onClick={() =>
                        navigate("/console/purchase/return/create")
                      }
                    />
                  </span>
                </Nav>
              </Container>
            </Navbar>
          </div>
          <div className="py-2 c-table">
            <TableUI
              headers={ProductHistory}
              body={userData}
              type="purchasereturn"
            ></TableUI>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PurchaseReturn;
