import React from "react";
import { Container, Nav, Navbar, Row, Col, Offcanvas } from "react-bootstrap";
import { Buttons } from "../components/Buttons";
import { ProductTable } from "../components/Tables";
import { PageTitle } from "../components/PageTitle";
import { useNavigate, useLocation } from "react-router-dom";
import { Calender, DropDownUI, TextInputForm } from "../components/Forms";
import { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { jsPDF } from "jspdf";
import API_DOMAIN from "../config/config";

const ProductHistory = [
  "S No",
  "Date",
  "Name",
  "Total",
  "Paid",
  "Balance",
  "Action",
];
const ProductHisData = [
  { values: ["1", "27/02/2024", "Siva", "12000", "5000", "20"] },
  { values: ["2", "28/02/2024", "shankar", "17895", "2000", "1500"] },
  { values: ["3", "29/02/2024", "Ranjith", "12563", "1785", "20000"] },
];
const SalesReturn = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  console.log(type, rowData);
  const [openFilter, setFilter] = useState(false);
  const closeFilter = () => setFilter(false);
  const showFilter = () => setFilter(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    });
  });
  const [userData, setUserData] = useState([]);
  const data = {
    RECEIPT_NO: "056/ENQ",
    DATE: "2023-10-31 17:47:27",
    CUSTOMER_NAME: "Barbikan Technologies Private Limited",
    CUSTOMER_PHONE: "9360692303",
    CUSTOMER_ADDRESS:
      "82/2-1 kullursandai road, Muthuraman Pati, Virudhunagar, Tamil Nadu.",
    PRODUCT_DATA: [
      {
        S_NO: "01",
        PRODUCT_NAME: "Coffee",
        QUANTITY: "10 Bag",
        RATE: "22",
        DISCOUNT: "10",
        TAX: "22",
        AMOUNT: "220",
      },
    ],
    TOTAL: "220",
    TAX: "18%",
    DISCOUNT: "18%",
    OVERALL_TOTAL: "220",
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Replace dynamic content in the HTML template
    const dynamicContent = `
          <!-- Your HTML content here -->
          <div>
            <h1>Receipt No: ${data.RECEIPT_NO}</h1>
            <p>Date: ${data.DATE}</p>
            <p>Customer Name: ${data.CUSTOMER_NAME}</p>
            <!-- Add more dynamic content here -->
          </div>
        `;

    // Render HTML content to PDF
    doc.html(dynamicContent, {
      callback: function (doc) {
        doc.save("sales_return.pdf");
      },
    });
  };
  useEffect(() => {
    if (type === "pdfview") {
      generatePDF();
    }
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://localhost:3000/salesreturn/list/joyg67j8t689t",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              search_text: "",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        console.log(responseData);
        setLoading(false);
        if (responseData.status === 200) {
          setUserData(responseData.data);
          console.log(setUserData);
        } else {
          throw new Error(responseData.msg);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData(); // Call fetchData directly in useEffect
  }, []);
  return (
    <div>
      <Container>
        <div className="main p-3 my-3">
          <Container>
            <div>
              <Navbar>
                <Navbar.Brand>
                  <PageTitle PageTitle={"Sale Returns"} />
                </Navbar.Brand>
                <Nav className="ms-auto">
                  <span className="mx-2 py-2">
                    {" "}
                    <Buttons lable={<>PDF</>} />
                  </span>
                  <span className="mx-2 py-2">
                    <Buttons lable={<>Filter</>} onClick={showFilter} />
                  </span>
                </Nav>
              </Navbar>
            </div>
            <Offcanvas show={openFilter} onHide={closeFilter} placement="end">
              <Offcanvas.Body>
                <Row>
                  <Col lg="6">
                    <PageTitle PageTitle={<>pay In</>} />
                  </Col>
                  <Col lg="6" className="align-self-center">
                    <div className="text-end">
                      <Buttons
                        onClick={closeFilter}
                        lable={
                          <>
                            <MdOutlineClose />
                          </>
                        }
                      ></Buttons>
                    </div>
                  </Col>
                </Row>
                <div>
                  <div className="py-3">
                    <TextInputForm labelname={<>Search Receipt</>} />
                  </div>
                  <div className="py-3">
                    <DropDownUI labelname={"Select Sales Party"} />
                  </div>
                  <Row>
                    <Col lg="6" className="align-self-center">
                      <div>
                        <Calender labelname={<>From</>} />
                      </div>
                    </Col>
                    <Col lg="6" className="align-self-center">
                      <div>
                        <Calender labelname={<>To</>} />
                      </div>
                    </Col>
                    <Col lg="6" className="align-self-center">
                      <div className="my-5 text-center">
                        <Buttons lable={"Apply"}></Buttons>
                      </div>
                    </Col>
                    <Col lg="6" className="align-self-center">
                      <div className="my-5 text-center">
                        <Buttons lable={"Clear"}></Buttons>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
            <div className="py-1">
              <Navbar>
                <Container>
                  <Navbar.Brand>
                    <PageTitle PageTitle={"Transactions"} />
                  </Navbar.Brand>
                  <Nav className="ms-auto">
                    <span className="mx-2 py-2">
                      {" "}
                      <Buttons
                        lable={<>Add New</>}
                        onClick={() => navigate("/console/sales/return/create")}
                      />
                    </span>
                  </Nav>
                </Container>
              </Navbar>
            </div>
            <div className="py-2 c-table">
              <ProductTable
                headers={ProductHistory}
                body={ProductHisData}
                type="salesreturn"
              ></ProductTable>
            </div>
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default SalesReturn;
