import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import API_DOMAIN from "../config/config";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    padding: 12,
  },
  section: {
    flexDirection: "column",
    flexGrow: 1,
    border: 1,
    display: "flex",
  },
  text: {
    fontSize: 13,
    fontFamily: "Times-Roman",
    textAlign: "center",
    borderBottom: 1,
    width: "100%",
  },
  // invoice-title start
  headertext: {
    fontSize: 10,
    fontFamily: "Times-Roman",
    borderBottom: 1,
    width: "100%",
    padding: 6,
  },
  head: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
  },
  date: {
    fontSize: 10,
    width: "50%",
    padding: 0,
    textAlign: "right",
  },
  title: {
    fontWeight: "bolder",
    fontSize: 13,
    textAlign: "center",
    width: "100%",
  },
  name: {
    fontSize: 10,
    width: "50%",
    textAlign: "left",
  },
  // invoice-title start
  // table start
  table: {
    display: "flex",
    padding: 6,
    justifyContent: "flex-end",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  tableCell: {
    flex: 4,
    fontSize: 9,
    fontFamily: "Times-Roman",
  },
  // table end
  // product start
  producthead: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    borderBottom: 1,
  },
  producttitle: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: 1,
    height: 50,
  },
  productbody: {
    fontSize: 8,
    fontFamily: "Times-Roman",
    display: "flex",
    flexDirection: "row",
    height: 100,
  },
  no: {
    width: "50%",
    padding: 5,
  },
  datarow: {
    width: "50%",
    marginTop: 80,
    padding: 5,
  },
  dataend: {
    width: "50%",
    padding: 5,
    marginTop: 80,
  },
});
const PayInPdf = ({ data }) => {
  const [userdata, setUserData] = useState([]);
  console.log("siva123", userdata);
  const filteredUserData = userdata.filter(
    (item) => item.company_id === data.party_details.company_id
  );
  console.log("Filtered userdata:", filteredUserData);

  function convertRupeesToWords(amount) {
    const ones = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands = ["", "Thousand", "Million", "Billion", "Trillion"];

    function convertGroup(number, thousandIndex) {
      const str = "000" + number;
      const hundred = ones[str[str.length - 3]];
      const ten = tens[str[str.length - 2]];
      const one = ones[str[str.length - 1]];

      const converted = [
        hundred === "" ? "" : hundred + " Hundred",
        ten === "" ? "" : ten,
        one === "" ? "" : one,
      ]
        .filter((word) => word !== "")
        .join(" ");

      return converted === "" ? "" : converted + " " + thousands[thousandIndex];
    }

    if (amount === 0) {
      return "Zero Rupees";
    }

    const groups = [];
    while (amount > 0) {
      groups.push(amount % 1000);
      amount = Math.floor(amount / 1000);
    }

    const words = groups
      .map((group, i) => convertGroup(group, i))
      .reverse()
      .join(" ")
      .trim();
    return words + " Rupees";
  }
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/company.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      //setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(responseData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      //setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const words = convertRupeesToWords(data.paid);
  console.log(words);

  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={styles.headertext}>
              <View style={styles.head}>
                <Text style={styles.title}>PayIn Receipt </Text>
              </View>
            </View>
            <View style={styles.headertext}>
              <View style={styles.head}>
                <View style={styles.name}>
                  <Text>Paid From </Text>
                  <Text>{data.party_details.company_name} </Text>
                  <Text>{data.party_details.billing_address}</Text>
                  <Text>Mobile : {data.party_details.mobile_number}</Text>
                </View>
                <View style={styles.name}>
                  <Text>Address</Text>
                  {/* {filteredUserData.length > 0 && <Text>{filteredUserData[0].company_name} </Text>}
                  {filteredUserData.length > 0 && <Text>{filteredUserData[0].address}</Text>}
                  {filteredUserData.length > 0 && <Text>Mobile : {filteredUserData[0].mobile_number}</Text>} */}
                  <Text>{data.company_details.company_name}</Text>
                  <Text>{data.company_details.address}</Text>
                  <Text>{data.company_details.gst_no}</Text>
                  <Text>{data.company_details.mobile_number}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.producthead}>
            <View style={styles.producttitle}>
              <Text style={styles.no}>Words : {words}</Text>
              <Text style={styles.no}>Paid : {data.paid}</Text>
            </View>
            <View style={styles.bodydescription}>
              <View style={styles.productbody}>
                <View style={styles.datarow}>
                  <Text>Customer Sign</Text>
                </View>
                {filteredUserData.length > 0 && (
                  <Text style={styles.dataend}>
                    For {data.company_details.company_name}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PayInPdf;
