import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { DropDownUI, TextInputForm } from "../components/Forms";
import API_DOMAIN from "../config/config";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { ClickButton } from "../components/Buttons";
const Login = ({ onLogin }) => {
  const [phone_number, setPhoneNumber] = useState("");
  const [company_id, setCompanyId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      if (phone_number === "" || company_id === "" || password === "") {
        throw new Error("Username Or Password is Empty");
      }
      if (!phone_number || !company_id || !password) {
        throw new Error("Username and Password are required");
      }

      const loginData = {
        phone_number: phone_number,
        company_id: company_id,
        password: password,
      };

      const response = await fetch(`${API_DOMAIN}/auth.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      // if (!response.ok) {
      //   throw new Error('Login failed');
      // }

      const responseData = await response.json();

      console.log(responseData);

      if (responseData.head.code !== 200) {
        setError(responseData.head.msg);
      } else if (responseData.head.code === 200) {
        localStorage.setItem("companyId", company_id);
        onLogin();
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Login error:", error.message);
      setError(error.message);
    }
  };
  const [userData, setUserData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/company.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(responseData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, []);
  return (
    <div className="login-bg">
      <Container fluid className="pad">
        <Row className="justify-content-center">
          <Col lg="4" md="6" xs="12" className="align-self-center p-0 m-0">
            <div className="login-box">
              <Row>
                <Col lg="12">
                  <div className="text-center">
                    <img
                      src={require("../assests/images/logo.png")}
                      className="img-fluid login-logo"
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg="12">
                  <div className="text-center fs-3 py-2">
                    Signin to Your Account
                  </div>
                  <div className="text-center py-2">
                    {" "}
                    Unleash Admin Excellence Get Started Today
                  </div>
                </Col>
                <Col lg="12">
                  <div className="py-3">
                    <DropDownUI
                      optionlist={userData.map((user) => ({
                        value: user.company_id,
                        label: user.company_name,
                      }))}
                      placeholder="CompanyId"
                      name="company_id"
                      value={company_id}
                      onChange={(e) => setCompanyId(e.company_id)}
                    />
                  </div>
                </Col>
                <Col lg="12">
                  <div className="py-3">
                    <TextInputForm
                      placeholder={"Phone Number"}
                      value={phone_number}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg="12">
                  <div className="py-3">
                    <TextInputForm
                      placeholder={"Password"}
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      suffix_icon={
                        showPassword ? (
                          <VscEye onClick={() => setShowPassword(false)} />
                        ) : (
                          <VscEyeClosed onClick={() => setShowPassword(true)} />
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <div className="py-3 text-center">
                <ClickButton label={<>Login</>} onClick={handleLogin} />
              </div>
              {error && (
                <Alert variant="danger">{error}</Alert> // Render error alert banner if error state is not null
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
